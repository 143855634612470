import Icon from '@common/components/Icon';
import { getMenu } from '@config/userLevelConfig/getConfig';
import {
  Avatar,
  Box,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import { userInfoSelector } from '@user/selector/userInfoSelector';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import DirectLink from '../../DirectLink';
import {
  AvatarWrapper,
  LogoWrapper,
  NavBarToolWrapper,
  NavItem,
  NavMenuContainer,
  Navbar
} from './styled';
import { useTranslation } from 'react-i18next';

export default function NavBar() {
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoSelector);
  const location = useLocation();
  const anchorPopover = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  // const onTabClick = item => {
  //   dispatch({ type: `${item.name.toUpperCase()}_INIT_STATE` });
  // };
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Logout = () => {
    handleClose();
    dispatch({ type: '@user/confirmLogout' });
  };

  const goToDetail = () => {
    window.location.href = `${process.env.REACT_APP_PHP_BASE_URL}/my-details.php`;
  };

  const navItems = getMenu(
    userInfo?.customer_type_id,
    userInfo.is_administrator
  );

  return (
    <>
      <Navbar>
        <NavBarToolWrapper>
          <LogoWrapper>
            <img src="/images/Logo.svg" alt="" />
          </LogoWrapper>
          <NavMenuContainer>
            {navItems?.map((item, index) => (
              <DirectLink
                // onClick={e => onTabClick(item)}
                to={item?.to ? item.to : undefined}
                href={`${process.env.REACT_APP_PHP_BASE_URL}${item?.href}`}
                sx={{
                  justifyContent: 'center'
                }}
                className="flex-center-rows"
                key={index}
              >
                <Tooltip title={t(item.name)} arrow placement="right">
                  <NavItem
                    active={
                      !!item.path?.filter(item => {
                        return !!matchPath(item, location.pathname);
                      }).length
                    }
                  >
                    <Icon icon={item.icon} color="white" size="md" />
                  </NavItem>
                </Tooltip>
              </DirectLink>
            ))}
          </NavMenuContainer>
        </NavBarToolWrapper>
        <Box>
          <Divider
            sx={{
              width: '49px',
              ml: 'auto',
              mr: 'auto',
              borderColor: '#0E5078 !important',
              display: { xs: 'none', md: 'block' }
            }}
          />
          <AvatarWrapper onClick={handleClick} ref={anchorPopover}>
            <Avatar
              className="fs-exclude"
              sx={{
                cursor: 'pointer',
                width: 48,
                height: 48,
                marginRight: { xs: 3, md: 0 }
              }}
              src={userInfo?.Avatar}
            />
          </AvatarWrapper>
        </Box>
      </Navbar>
      <Menu
        open={Boolean(open)}
        anchorEl={anchorPopover.current}
        onClose={handleClose}
        sx={{
          ml: { xs: 2, md: 0 }
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem onClick={goToDetail} disableRipple>
          <Icon icon="icon-profile" size="sm" />
          <Typography
            color="neutral.dark3"
            variant="body2"
            component="div"
            sx={{ ml: 1 }}
          >
            {t('my_details')}
          </Typography>
        </MenuItem>
        <MenuItem onClick={Logout} disableRipple>
          <Icon icon="icon-Logout" size="sm" />
          <Typography
            color="neutral.dark3"
            variant="body2"
            component="div"
            sx={{ ml: 1 }}
          >
            {t('log_out')}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
