import { styled } from '@mui/material';

export const CrewHeader = styled('h2', { name: 'CrewHeader' })(({ theme }) => ({
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '40px',
  color: theme.palette.primary.dark3,
  margin: 0
}));

export const Body = styled('div', { name: 'CrewDetailBody' })(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  padding: '32px 0'
}));
