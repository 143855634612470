import { USER_LEVEL } from '@constant/user_level';
import {
  AircraftSection,
  CrewSection,
  FlightDetailHeader,
  FlightMap,
  HistorySection,
  InvoiceSection,
  LegInfo,
  NotesSection,
  ParkingSection,
  PassengerSection,
  PocSection
} from '@flight/components/flight-detail';
import { FlightDetailProvider } from '@flight/context/flightDetailContext';
import { polylineConvert } from '@helper/stringFunction';
import { Box, Skeleton } from '@mui/material';
import { useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import AuthorizedComponent from 'src/hoc/AuthorizedComponent';
import useAirportCode from 'src/hooks/useAirportCode';
import useFetchDetail from 'src/hooks/useFetchDetail';
import { DetailInfo, GeneralInfo, Wrapper } from './styled';
const FlightDetail = () => {
  const { id } = useParams();
  const airportCodeConfig = useAirportCode();
  const [activeLeg, setActiveLeg] = useState(0);
  const printRef = useRef(); // ref for print detail page

  // GET FLIGHT DETAIL DATA
  const [flightData] = useFetchDetail(
    'flight',
    'flight',
    '/flights/:id',
    parseInt(id)
  );
  const { flight_lines, flight_status } = flightData;
  const mapData = flight_lines?.map((item: Record<string, any>) => {
    return polylineConvert(item, airportCodeConfig);
  });
  const legData = flight_lines?.[activeLeg];

  // Function
  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveLeg(newValue);
  };

  return (
    <FlightDetailProvider
      flightData={flightData} // FLight head data
      legData={legData} // Active leg data
      activeLeg={activeLeg} // index of active leg
      setActiveLeg={setActiveLeg} // on change leg
      flight_lines={flight_lines} // leg list data
    >
      <Wrapper ref={printRef}>
        <FlightDetailHeader handlePrint={handlePrint} />
        <GeneralInfo>
          <Box sx={{ height: '100%', flex: 1 }}>
            {flight_lines?.length > 0 ? (
              <FlightMap mapData={mapData} />
            ) : (
              <Skeleton variant="rectangular" width="100%" height="100%" />
            )}
          </Box>
          <Box sx={{ width: '400px', height: '100%', px: 4, py: 3, zIndex: 2 }}>
            <LegInfo handleChange={handleChange} />
          </Box>
        </GeneralInfo>
        {flight_lines ? (
          <DetailInfo>
            {/*----------------POINT OF CONTACT-----------------------------------*/}
            <PocSection />
            {/*-------------------PAX LIST--------------------------------*/}
            <PassengerSection />
            {/*--------------------AIRCRAFT & OPERATOR-------------------------------*/}
            <AircraftSection />
            {/*-------------------CREW--------------------------------*/}
            <AuthorizedComponent
              requiredRoles={[
                USER_LEVEL.BROKER,
                USER_LEVEL.SUPERUSER,
                USER_LEVEL.OPERATOR
              ]}
            >
              <CrewSection />
            </AuthorizedComponent>
            {/*-----------------REQUEST PARKING----------------------------------*/}
            <AuthorizedComponent
              requiredRoles={[USER_LEVEL.BROKER, USER_LEVEL.SUPERUSER]}
            >
              <ParkingSection />
            </AuthorizedComponent>
            {/*-----------------INVOICE----------------------------------*/}
            <AuthorizedComponent
              requiredRoles={[USER_LEVEL.CLIENT, USER_LEVEL.SUPERUSER]}
            >
              <InvoiceSection flight_status={flight_status} />
            </AuthorizedComponent>
            {/*-------------NOTES FOR LEG--------------------------------------*/}
            <AuthorizedComponent requiredRoles={[USER_LEVEL.SUPERUSER]}>
              <NotesSection />
            </AuthorizedComponent>
            {/*------------MOST RECENT EDITS---------------------------------------*/}
            <AuthorizedComponent
              requiredRoles={[USER_LEVEL.BROKER, USER_LEVEL.SUPERUSER]}
            >
              <HistorySection />
            </AuthorizedComponent>
          </DetailInfo>
        ) : (
          <DetailInfo>
            <Skeleton />
            <Skeleton />
          </DetailInfo>
        )}
      </Wrapper>
    </FlightDetailProvider>
  );
};

export default FlightDetail;
