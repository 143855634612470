import { createSlice } from '@reduxjs/toolkit';
import { createResourceReducers } from '@services/createResourceReducer';

const initialState = {
  crew: {
    data: {},
    paging: {}
  }
};

export const crewReducer = createSlice({
  name: 'crew',
  initialState,
  reducers: {
    ...createResourceReducers('crew')
  }
});
