import Icon from '@common/components/Icon';
import { useFlightDetail } from '@flight/context/flightDetailContext';
import { SectionWrapper } from '@flight/pages/flight-detail/styled';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NotesSection = () => {
  const { t } = useTranslation();
  const { activeLeg } = useFlightDetail();
  return (
    <SectionWrapper>
      <Box className="flex-center-rows" sx={{ gap: 1, mb: 4 }}>
        <Icon
          icon="icon-file-text-fill"
          size="lg"
          sx={{ color: 'primary.dark1' }}
        />
        <Typography variant="head3Mons" color="primary.dark2">
          {t('notes_for_leg_n', { activeLeg: activeLeg + 1 })}
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default NotesSection;
