/*
name: core.block.listItem
notLazy: true
*/

import ListPagination from '@common/components/ListItemPagination';
import { useGlobal } from '@context/GlobalContext';
import { Box } from '@mui/material';
import { get, max } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import useEntityFullfil from 'src/hooks/useEntityFullfil';

export default function BlockList({
  itemName,
  itemProps,
  emptyName,
  itemPerPage,
  module,
  resource,
  apiUrl,
  hasPagination,
  initParams,
  canLoadMore,
  apiParams
}: {
  itemName: string;
  itemProps?: Record<string, any>;
  emptyName: string;
  itemPerPage: number;
  module: string;
  resource: string;
  apiUrl: string;
  hasPagination: boolean;
  initParams?: Record<string, any>;
  canLoadMore?: boolean;
  apiParams?: Record<string, any>;
}) {
  const { jsxBackend } = useGlobal();
  const dispatch = useDispatch();
  const ItemView = jsxBackend.get(itemName);
  const ItemViewSkelton = jsxBackend.get(itemName + 'Skeleton');
  const Empty = jsxBackend.get(emptyName);

  const [searchParams, setSearchParams] = useSearchParams(initParams);
  const params = useMemo(() => {
    return {
      ...Object.fromEntries(searchParams.entries()),
      limit: itemPerPage,
      page: canLoadMore ? undefined : searchParams.get('page') ?? '1'
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const [data, loading] = useEntityFullfil(
    module,
    resource,
    apiUrl,
    apiParams ? apiParams : params,
    canLoadMore
  );

  const page = parseInt(searchParams.get('page'));
  const last_page = get(data, 'meta.last_page', null);

  useEffect(() => {
    if (canLoadMore) return;
    // Check if the 'page' parameter exists
    if (!searchParams.has('page')) {
      setSearchParams({ ...Object.fromEntries(searchParams), page: '1' });
    }
    if (
      searchParams.has('page') &&
      last_page &&
      parseInt(searchParams.get('page')) > parseInt(last_page)
    ) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page: last_page
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [last_page, searchParams]);

  useEffect(() => {
    return () => {
      dispatch({
        type: `${module}/refreshPaging`
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {loading && !data?.next_cursor ? (
        Array(max([itemPerPage, 10]))
          .fill(0)
          .map((_, index) => {
            return ItemViewSkelton ? <ItemViewSkelton key={index} /> : null;
          })
      ) : data?.data.length ? (
        data?.data.map((id: number, index: number) => {
          return <ItemView key={index} id={id} {...itemProps} />;
        })
      ) : (
        <Empty />
      )}
      {data.next_cursor && loading && canLoadMore
        ? Array(5)
            .fill(0)
            .map((_, index) => <ItemViewSkelton key={index} />)
        : null}
      {hasPagination && !canLoadMore ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            margin: '8px 0 24px'
          }}
        >
          <ListPagination page={page} data={data.meta} />
        </Box>
      ) : null}
    </>
  );
}
