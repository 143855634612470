import Icon from '@common/components/Icon';
import { USER_LEVEL } from '@constant/user_level';
import { useFlightDetail } from '@flight/context/flightDetailContext';
import { SectionWrapper } from '@flight/pages/flight-detail/styled';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router';
import AuthorizedComponent from 'src/hoc/AuthorizedComponent';
import { EmptyBox } from '../styled';
import { PocItem } from './PocItem';
import { useTranslation } from 'react-i18next';

const PocSection = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { customer: mainPoc, customer_2: secondPoc } =
    useFlightDetail().flightData;
  const onEdit = () => {
    window.location.href = `${process.env.REACT_APP_PHP_BASE_URL}/flights.php?edit=${id}`;
  };

  const isEmptyPoc = !mainPoc && !secondPoc;
  return (
    <SectionWrapper>
      <Box className="flex-center-rows" sx={{ gap: 1, mb: 4 }}>
        <Icon
          icon="icon-Users-filled"
          size="lg"
          sx={{ color: 'primary.dark1' }}
        />
        <Typography variant="head3Mons" color="primary.dark2">
          {t('point_of_contact')}
        </Typography>

        {isEmptyPoc ? (
          <AuthorizedComponent
            requiredRoles={[USER_LEVEL.SUPERUSER, USER_LEVEL.BROKER]}
          >
            <Box
              className="flex-column"
              sx={{ flex: 1, alignItems: 'flex-end' }}
            >
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                startIcon={
                  <Icon
                    icon="icon-Plus-filled"
                    sx={{ color: 'primary.dark2' }}
                  />
                }
                onClick={onEdit}
              >
                {t('add_point_of_contact')}
              </Button>
            </Box>
          </AuthorizedComponent>
        ) : null}
      </Box>

      {isEmptyPoc ? (
        <EmptyBox>
          <Typography variant="body2" color="neutral.dark1">
            {t('no_point_of_contact_added_this_flight')}
          </Typography>
        </EmptyBox>
      ) : (
        <Grid container columnSpacing={4}>
          {[mainPoc, secondPoc].map(
            (item: Record<string, any>, index: number) => {
              return item ? (
                <Grid item xs={6} key={index}>
                  <PocItem data={mainPoc} />
                </Grid>
              ) : null;
            }
          )}
        </Grid>
      )}
    </SectionWrapper>
  );
};

export default PocSection;
