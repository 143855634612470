import * as React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Init18n, i18n } from './i18n';
import { useGlobal } from '@context/GlobalContext';
import { get } from 'lodash';

export default function LocaleProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const { config } = useGlobal(); // Assuming useGlobal provides locale config
  const selectedLocale = get(config, 'config.locale', 'en');

  // Use useLayoutEffect to ensure i18n is ready before the first paint
  React.useLayoutEffect(() => {
    Init18n('vi');
  }, [selectedLocale]);

  return (
    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
      {children}
    </I18nextProvider>
  );
}
