import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useField } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
const SelectForm = ({ config, name }: any) => {
  const { t } = useTranslation();
  const [field, meta, { setValue, setTouched }] = useField(name);
  const [focus, setFocus] = useState<boolean>(false);

  const { options, label, required } = config;
  const onBlur = (e: any) => {
    field.onBlur(e);
    setTouched(true);
    setFocus(false);
  };
  const onClickFocus = (e: any) => {
    setFocus(true);
  };

  const handleChange = (event: any) => {
    setValue(event.target.value as string);
  };
  const showError = !focus && meta.error && meta.touched;
  return (
    <FormControl fullWidth variant="filled" size="small">
      <InputLabel
        error={showError}
        required={required}
        id="demo-simple-select-standard-label"
      >
        {label}
      </InputLabel>
      <Select
        required={required}
        labelId="demo-simple-select-standard-label"
        value={field.value}
        onChange={handleChange}
        error={showError}
        onClick={onClickFocus}
        onBlur={onBlur}
      >
        {options?.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              <span>{item.label}</span>
            </MenuItem>
          );
        })}
      </Select>
      {showError ? <div className="errorMessage">{t(meta.error)}</div> : null}
    </FormControl>
  );
};

export default SelectForm;
