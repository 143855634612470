import { PaginationBar } from '@common/components/Pagination';
import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';

type PagingDataProps = {
  total: number;
  last_page: number;
};

const ListPagination = ({
  page,
  data,
  reloadWhen = []
}: {
  startDate?: string;
  endDate?: string;
  page: number;
  data: PagingDataProps;
  reloadWhen?: any[];
}) => {
  const [loadPagination, setLoadPagination] = useState(false);

  const [pagingData, setPagingData] = useState({
    total: null,
    last_page: null
  });
  useEffect(() => {
    if (loadPagination) {
      if (data) {
        setPagingData(data);
        setLoadPagination(false);
      } else {
        setPagingData({
          total: null,
          last_page: null
        });
      }
    } else {
      data && setPagingData(data);
    }
  }, [data, loadPagination]);

  useEffect(() => {
    setLoadPagination(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, reloadWhen);
  return (
    <>
      {pagingData.last_page !== null ? (
        pagingData.total === 0 ? null : (
          <PaginationBar
            count={pagingData.last_page}
            page={page}
            color="primary"
          />
        )
      ) : (
        <Skeleton variant="text" width={150} height={32} />
      )}
    </>
  );
};

export default ListPagination;
