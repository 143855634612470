import { PayloadAction } from '@reduxjs/toolkit';
import { get, unset, reduce, filter } from 'lodash';

// Define a function to create action handlers for a specific defaultResource
export const createResourceReducers = defaultResource => ({
  INIT: (
    state,
    action: PayloadAction<{ paging: string; resource: string }>
  ) => {
    const { paging, resource = defaultResource } = action.payload;

    // Ensure defaultResource exists in the state
    if (!state[resource]) {
      console.error(`Resource '${defaultResource}' not found in the state.`);
      return;
    }

    state[resource].paging[paging] = {
      ...state[resource].paging[paging],
      loading: true,
      refreshing: false
    };
  },

  FULLFIL: (
    state,
    action: PayloadAction<{
      data: any[];
      meta: any;
      paging: string;
      resource: string;
    }>
  ) => {
    const { data, meta, paging, resource = defaultResource } = action.payload;

    // GET CURRENT PAGING DATA
    const pagingData: number[] = get(
      state,
      [resource, 'paging', paging, 'data'],
      []
    );
    const fullfilData = {};

    // MAP ARRAY DATA TO OBJECT ENTITY
    data.forEach(item => {
      fullfilData[item.id] = item;
      pagingData.push(item.id);
    });

    // UPDATE NEW OBJECT ENTITY
    state[resource].data = {
      ...state[resource].data,
      ...fullfilData
    };

    // UPDATE PAGING DATA
    state[resource].paging[paging] = {
      data: pagingData,
      next_cursor: meta?.next_cursor || 'end',
      meta: {
        total: meta?.total,
        last_page: meta?.last_page
      },
      refreshing: false
    };
  },

  fetchDetail: (
    state,
    action: PayloadAction<{ data: any; identity: string }>
  ) => {
    const { data, identity } = action.payload;
    const [, resource = defaultResource, id] = identity.split('.');

    if (!state[resource]) {
      console.error(`Resource '${resource}' not found in the state.`);
      return;
    }

    state[resource].data = {
      ...state[resource].data,
      [id]: data
    };
  },

  deleteEntity: (state, action: PayloadAction<{ identity: string }>) => {
    const { identity } = action.payload;
    const [, resource = defaultResource, id] = identity.split('.');

    if (!state[resource]) {
      console.error(`Resource '${resource}' not found in the state.`);
      return;
    }

    // Step 1: Use lodash `unset` to remove the entity by id
    unset(state[resource].data, id);

    // Step 2: Update the paging structure
    state[resource].paging = reduce(
      state[resource].paging,
      (result, pageData, page) => {
        const filteredClientIds = filter(
          pageData.data,
          entityId => entityId !== Number(id)
        );

        // Build the updated paging object
        result[page] = {
          ...pageData,
          data: filteredClientIds
        };

        return result;
      },
      {}
    );
  },

  updateEntity: (
    state,
    action: PayloadAction<{ identity: string; data: any }>
  ) => {
    const { identity, data } = action.payload;
    const [, resource = defaultResource, id] = identity.split('.');

    if (!state[resource]) {
      console.error(`Resource '${resource}' not found in the state.`);
      return;
    }

    state[resource].data = {
      ...state[resource].data,
      [id]: {
        ...state[resource].data[id],
        ...data
      }
    };
  },

  refreshPaging: (state, action: PayloadAction<{ resource?: string }>) => {
    const resource = action?.payload?.resource ?? defaultResource;
    if (!state[resource]) {
      console.error(`Resource '${resource}' not found in the state.`);
      return;
    }

    Object.keys(state[resource].paging).forEach(key => {
      state[resource].paging[key] = {
        refreshing: true,
        data: [],
        next_cursor: null
      };
    });
  }
});
