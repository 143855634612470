import { useRegister } from '@context/RegisterContext';
import { CircleRound, NavigateWrapper, StepItem } from './styled';
import { Typography } from '@mui/material';
import Icon from '@common/components/Icon';
import { useTranslation } from 'react-i18next';

const STEP_LIST = [
  {
    step: 1,
    label: 'confirm_details_and_data_retention'
  },
  {
    step: 2,
    label: 'verify_your_identity'
  },
  {
    step: 3,
    label: 'add_your_allergies'
  },
  {
    step: 4,
    label: 'secure_your_account'
  }
];
export enum STATUS {
  NOT_PASSED = 0,
  CURRENT = 1,
  PASSED = 2
}

export default function RegisterNavigate(props: any) {
  const { t } = useTranslation();
  const { step } = useRegister();
  function statusStep(stepCheck: number) {
    if (step > stepCheck) return STATUS.PASSED;
    if (step === stepCheck) return STATUS.CURRENT;
    return STATUS.NOT_PASSED;
  }
  return (
    <NavigateWrapper step={step}>
      <img src="/images/logo_line.svg" alt="" className="logo-southend" />
      {STEP_LIST.map((item, index) => {
        return (
          <StepItem key={index} status={statusStep(item.step)}>
            {statusStep(item.step) === STATUS.PASSED ? (
              <Icon icon="icon-check-circle-fill" color="white" />
            ) : (
              <CircleRound />
            )}
            <Typography sx={{ flex: 1 }} variant="body1" color="neutral.white">
              {t(item.label)}
            </Typography>
          </StepItem>
        );
      })}
    </NavigateWrapper>
  );
}
