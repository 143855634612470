import Icon from '@common/components/Icon';
import { CheckboxForm, TextFieldForm } from '@common/formElement';
import { useRegister } from '@context/RegisterContext';
import { Box, Button, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import SecureQuestionForm from '../common/SecureQuestionSelect';
import { Content, FooterAction } from './_styled';
import { useTranslation } from 'react-i18next';

const SecureAccountSchema = Yup.object().shape({
  secQuestion: Yup.string().required('Please select your security question'),
  secAnswer: Yup.string().required('Please input your security answer')
});

export default function SecureAccount() {
  const { t } = useTranslation();
  const { backStep, token, userDetail, setUserDetail, nextStep } =
    useRegister();
  const dispatch = useDispatch();

  const handleSubmit = async (value: any, formik: FormikHelpers<any>) => {
    dispatch({
      type: '@register/secureAccount',
      payload: {
        value,
        token,
        setUserDetail,
        nextStep
      }
    });
  };

  const initValue = {
    secQuestion: userDetail.secQuestion ?? '',
    secAnswer: userDetail.secAnswer ?? '',
    twoStep: userDetail.twoStep ?? 0
  };

  return (
    <>
      <Box sx={{ height: '100%' }} className="flex-column">
        <Typography
          sx={{ mt: { xs: 1, md: 4 } }}
          component="div"
          variant="body2"
          color="neutral.dark3"
        >
          {t('ensure_only_have_access_your_account_with_2fa')}
        </Typography>
        <Box sx={{ mt: 3, flex: 1 }}>
          <Formik
            initialValues={initValue}
            onSubmit={handleSubmit}
            validationSchema={SecureAccountSchema}
            validateOnMount
          >
            {({ isValid, initialValues }) => (
              <Form
                style={{ height: '100%' }}
                className="flex-space-between-column"
              >
                <Content>
                  <SecureQuestionForm
                    name="secQuestion"
                    config={{
                      size: 'medium',
                      label: t('security_question'),
                      required: true
                    }}
                  />
                  <Box sx={{ mt: 2 }}>
                    <TextFieldForm
                      name="secAnswer"
                      config={{
                        size: 'medium',
                        label: t('your_security_answer'),
                        fullWidth: true,
                        required: true
                      }}
                      initialValues={initialValues}
                    />
                  </Box>

                  <Box sx={{ mt: 4 }}>
                    <CheckboxForm
                      name="twoStep"
                      config={{
                        size: 'small',
                        label: t('enable_2fa')
                      }}
                      initialValues={initialValues}
                    />
                  </Box>
                </Content>
                <FooterAction>
                  <Button
                    onClick={backStep}
                    size="large"
                    variant="outlined"
                    color="inherit"
                    startIcon={<Icon icon="icon-previous-arrow" />}
                  >
                    {t('previous_step')}
                  </Button>
                  <Button
                    type="submit"
                    // onClick={submitForm}
                    size="large"
                    variant="contained"
                    disabled={!isValid}
                    color="primary"
                    endIcon={
                      <Icon
                        icon="icon-previous-arrow"
                        className="icon-flip-x"
                        color="lightAccent"
                      />
                    }
                  >
                    {t('complete_registration')}
                  </Button>
                </FooterAction>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
}
