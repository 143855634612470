import { entitySelector } from '@core/selector/entitySelector';
import { userInstance } from '@instance/userInstance';
import handleActionError from '@services/handleActionError';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import router from '@utils/route/router';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { put, select, takeEvery } from 'redux-saga/effects';

export function* addClient(action: any): Record<string, any> {
  const { setSubmitting, closeDialog } = action.meta;
  try {
    const { data } = action.payload;
    setSubmitting(true);
    const response = yield userInstance.request({
      url: '/clients',
      method: 'POST',
      data
    });

    const returnData = response?.data?.data?.client;
    if (returnData) {
      yield put({
        type: '@entity/REFRESH',
        payload: {
          module: 'client'
        }
      });
      yield enqueueSnackbar(response?.data?.message, { variant: 'success' });
      setSubmitting(false);
      closeDialog();
    }
  } catch (err) {
    setSubmitting(false);
    yield handleActionError(err);
  }
}

export function* updateClient(action: any): Record<string, any> {
  try {
    const { data, userId } = action.payload;
    const { setSubmitting, closeDialog } = action.meta;
    setSubmitting(true);
    const response = yield userInstance.request({
      url: `/clients/${userId}`,
      method: 'PUT',
      data
    });

    const returnData = response?.data?.data;
    if (returnData) {
      yield put({
        type: 'client/updateEntity',
        payload: { identity: `client.client.${userId}`, data: returnData }
      });
      setSubmitting(false);
      closeDialog();
      yield enqueueSnackbar(response?.data?.message, { variant: 'success' });
    }
  } catch (err) {
    const { setSubmitting } = action.meta;
    setSubmitting(false);
    yield handleActionError(err);
  }
}

export function* addRoute(action: any): Record<string, any> {
  try {
    const { data, id } = action.payload;
    const response = yield userInstance.request({
      url: `/clients/${id}/empty-legs`,
      method: 'POST',
      data
    });

    const returnData = response?.data?.data;
    const item = yield select(state =>
      entitySelector(state, 'client', 'client', id)
    );

    yield put({
      type: 'client/updateEntity',
      payload: {
        identity: `client.client.${id}`,
        data: { ...item, emptyLegs: [...item.emptyLegs, returnData] }
      }
    });
    if (returnData) {
      yield enqueueSnackbar(response?.data?.message, { variant: 'success' });
    }
  } catch (err) {
    yield handleActionError(err);
  }
}

export function* deleteRoute(action: any): Record<string, any> {
  try {
    const { user_id, route_id } = action.payload;
    const { setDeleting } = action.meta;
    const ok = yield DialogJSX.confirm({
      props: {
        title: 'Remove client',
        content: <span>Are you sure you want to remove this route?</span>,
        submitButtonLabel: 'Yes, I want to remove this',
        cancelButtonLabel: 'Close'
      },
      inMobile: 'full'
    });
    if (!ok) return;
    setDeleting(true);

    const response = yield userInstance.request({
      url: `/clients/${user_id}/empty-legs/${route_id}`,
      method: 'DELETE'
    });

    const returnData = response?.data?.data;
    const item = yield select(state =>
      entitySelector(state, 'client', 'client', user_id)
    );

    yield put({
      type: 'client/updateEntity',
      payload: {
        identity: `client.client.${user_id}`,
        data: {
          ...item,
          emptyLegs: item.emptyLegs.filter(item => item.id !== route_id)
        }
      }
    });
    if (returnData) {
      yield enqueueSnackbar(response?.data?.message, { variant: 'success' });
    }
  } catch (err) {
    yield handleActionError(err);
  } finally {
    const { setDeleting } = action.meta;
    setDeleting(false);
  }
}

export function* showAddNewDialog(action: any): Record<string, any> {
  yield DialogJSX.open({
    component: 'client.dialog.inviteClient',
    props: {
      title: 'add_new_client',
      submitButtonLabel: 'send_invite',
      cancelButtonLabel: 'close'
    },
    inMobile: 'full'
  });
}

export function* deleteClient(action: any): Record<string, any> {
  const { id, clientName, isDetail = false } = action.payload;
  try {
    const ok = yield DialogJSX.confirm({
      props: {
        title: 'remove_client',
        content: clientName
          ? 'are_you_sure_remove_client_name'
          : 'are_you_sure_remove_this_client',
        contentProps: { clientName },
        submitButtonLabel: 'yes_i_want_to_remove_this',
        cancelButtonLabel: 'close'
      },
      inMobile: 'full'
    });
    if (ok) {
      yield put({
        type: 'client/updateEntity',
        payload: { identity: `client.client.${id}`, data: { deleting: true } }
      });
      const response = yield userInstance.request({
        url: `/clients/${id}`,
        method: 'delete'
      });
      if (response.data.status === 'success') {
        yield put({
          type: 'client/refreshPaging'
        });
        if (isDetail) {
          router.navigate('/clients');
        }
        yield enqueueSnackbar(t('client_deleted_success'), {
          variant: 'success'
        });
      } else {
      }
    }
  } catch (err) {
    yield put({
      type: 'client/updateEntity',
      payload: { identity: `client.client.${id}`, data: { deleting: false } }
    });
    yield handleActionError(err);
  }
}

export function* resendEmail(action: any): Record<string, any> {
  const { id } = action.payload;
  try {
    const response = yield userInstance.request({
      url: `/clients/${id}/resend-invite`,
      method: 'get'
    });
    if (response.data.status === 'success') {
      yield enqueueSnackbar(t('resend_invite_success'), {
        variant: 'success'
      });
    } else {
    }
  } catch (err) {
    yield handleActionError(err);
  }
}

const sagaEffect = [
  takeEvery('@clients/showAddNewDialog', showAddNewDialog),
  takeEvery('@clients/deleteClient', deleteClient),
  takeEvery('@clients/onResendEmail', resendEmail),
  takeEvery('@client/addNewClient', addClient),
  takeEvery('@client/updateClient', updateClient),
  takeEvery('@client/addNewRoute', addRoute),
  takeEvery('@client/deleteRoute', deleteRoute)
];

export default sagaEffect;
