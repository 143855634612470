/*
name: flight.item.embedItemClientDetailSkeleton
notLazy: true
*/

import { Grid, Skeleton } from '@mui/material';
import { FlightEmbedItemWrapper, TableWrapper } from './styled';
const FlightEmbedItemSkeleton = () => {
  return (
    <FlightEmbedItemWrapper>
      <TableWrapper>
        <Grid container>
          <Grid sx={{ pl: 2 }} item xs={2}>
            <Skeleton variant="text" width={40} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" width={120} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="text" width={80} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="text" width={80} />
          </Grid>
        </Grid>
      </TableWrapper>
    </FlightEmbedItemWrapper>
  );
};

export default FlightEmbedItemSkeleton;
