import { userId } from '@user/selector/userInfoSelector';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import { useSelector } from 'react-redux';
import { AvatarWrapper, Badge, PocWrapper } from './styled';
import { Avatar, Box, Button } from '@mui/material';
import TruncatedText from '@common/components/TruncatedText';
import { getName } from '@helper/stringFunction';
import Icon from '@common/components/Icon';
import { useTranslation } from 'react-i18next';

export const PocItem = ({ data }) => {
  const { t } = useTranslation();
  const uid = useSelector(userId);

  const onChat = () => {
    DialogJSX.comingsoon();
  };

  const isMyself = uid === data?.id;
  return (
    <PocWrapper>
      <AvatarWrapper>
        <Avatar
          className="fs-exclude"
          variant="circular"
          sx={{
            width: 48,
            height: 48
          }}
          src={data?.onfido_image}
        />
        {isMyself ? <Badge>{t('myself')}</Badge> : null}
      </AvatarWrapper>
      <Box
        className="flex-column"
        sx={{ alignItems: 'flex-start', flex: 1, gap: 0.25, minWidth: 0 }}
      >
        <TruncatedText variant="subtitle2" color="neutral.dark3">
          {t('name')}
        </TruncatedText>
        <TruncatedText variant="subtitle1" noWrap color="neutral.dark3">
          <b>{getName(data) ?? 'N/A'}</b>
        </TruncatedText>
      </Box>
      {!isMyself ? (
        <Box
          className="flex-column"
          sx={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
        >
          <Button
            variant="outlined"
            color="inherit"
            size="medium"
            startIcon={
              <Icon icon="icon-message" sx={{ color: 'primary.dark2' }} />
            }
            onClick={onChat}
          >
            {t('message')}
          </Button>
        </Box>
      ) : null}
    </PocWrapper>
  );
};
