import { TextFieldForm } from '@common/formElement';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Identify = ({ initialValues }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
          <Grid item xs={12} sm={4}>
            <TextFieldForm
              name="firstName"
              config={{
                label: t('first_name'),
                readOnly: true
              }}
              initialValues={initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextFieldForm
              name="middleName"
              config={{
                label: t('middle_name'),
                readOnly: true
              }}
              initialValues={initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextFieldForm
              name="lastName"
              config={{
                label: t('last_name'),
                readOnly: true
              }}
              initialValues={initialValues}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
          <Grid item xs={12} sm={6}>
            <TextFieldForm
              name="date_of_birth"
              config={{
                label: t('date_of_birth'),
                readOnly: true
              }}
              initialValues={initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldForm
              name="country"
              config={{
                label: t('issuing_country'),
                readOnly: true
              }}
              initialValues={initialValues}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
          <Grid item xs={12} sm={4}>
            <TextFieldForm
              name="passport_number"
              config={{
                label: t('passport_licence_number'),
                readOnly: true
              }}
              initialValues={initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextFieldForm
              name="passport_issue_date"
              config={{
                label: t('valid_from'),
                readOnly: true
              }}
              initialValues={initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextFieldForm
              name="passport_expiry_date"
              config={{
                label: t('valid_to'),
                readOnly: true
              }}
              initialValues={initialValues}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Identify;
