import { FLIGHT_STATUS } from '@constant/flight_status';
import { isArray } from 'lodash';

export function shortenName(name: string): string {
  if (!name) return 'NA';

  const cs = name
    .trim()
    .split(/\s+/g)
    .splice(0, 2)
    .map(x => String.fromCodePoint(x.codePointAt(0)).toUpperCase());

  if (name.split(/\s+/g).length === 1) {
    return name.slice(0, 2).toUpperCase();
  }

  return 1 < name.length
    ? cs.join('')
    : Array.from(cs)
        .splice(0, 2)
        .map(x => x.toUpperCase())
        .join('');
}

function hashCode(str: string) {
  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

export function formatPhone(str: string) {
  // PHONE UK FORMAT: +XX XXXX XXXXX
  let reg = new RegExp(String.raw`(\d{4})(\d{${str.length - 4}})`);

  return str.replace(reg, '$1 $2');
}

export function pickColor(str: string) {
  return `linear-gradient(to left bottom, hsl(${
    hashCode(str) % 360
  }, 100%, 70%) 10%,  hsl(${hashCode(str) % 360}, 100%, 35%) 70%)`;
}

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function joinCatering(cateringList: Record<string, any>[]) {
  if (!isArray(cateringList) || cateringList?.length < 1) {
    return null;
  }
  if (cateringList?.length === 1) {
    return cateringList[0].name;
  }
  return cateringList
    ?.slice(0, -1)
    ?.map(item => {
      return item.name;
    })
    .join(', ')
    .concat(' and ')
    .concat(cateringList.at(-1).name);
}

export function flatStringParams(params: Record<string, any>) {
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== undefined)
  );
  const pagingParam = new URLSearchParams(filteredParams);
  pagingParam.sort();
  return pagingParam.toString().length ? `?${pagingParam.toString()}` : '';
}

export const tooltipPassportStatusData = (value: number) => {
  switch (value) {
    case 1: {
      return {
        text: 'awaiting_client_acceptance',
        icon: 'icon-mail-pending',
        color: 'warning'
      };
    }
    case 2: {
      return {
        text: 'verifying_passport',
        icon: 'icon-passport-pending-bold',
        color: 'primary'
      };
    }
    case 3: {
      return {
        text: 'passport_verified',
        icon: 'icon-passport-bold',
        color: 'positive'
      };
    }
    case 4: {
      return {
        text: 'passport_rejected',
        icon: 'icon-passport-fail-bold',
        color: 'accent'
      };
    }
  }
};

export const tooltipPassportStatusDataForCrew = (value: number) => {
  switch (value) {
    case 1:
      return {
        text: 'Awaiting crew acceptance',
        color: 'warning',
        icon: 'icon-passport-pending-bold'
      };
    case 3:
      return {
        text: 'Identify verified',
        color: 'positive',
        icon: 'icon-passport-bold'
      };
    case 4:
      return {
        text: 'Identify rejected',
        color: 'accent',
        icon: 'icon-passport-fail-bold'
      };
    default:
      return {
        text: 'Identify verifying',
        color: 'primary',
        icon: 'icon-passport-pending-bold'
      };
  }
};

export const getName = (data: Record<string, any>) => {
  if (!data) return null;
  const { firstName, lastName, email } = data;
  if (!firstName && !!lastName && !email) return null;
  return firstName || lastName
    ? `${firstName ?? ''} ${lastName ?? ''}`.trim()
    : email;
};

export const tooltipFlightStatusData = (value: number) => {
  switch (value) {
    case FLIGHT_STATUS.ENQUIRY: {
      return {
        text: 'Enquiry',
        color: 'accent.light3',
        border: 'danger.main',
        textColor: 'danger.main'
      };
    }
    case FLIGHT_STATUS.AC_SIGNED: {
      return {
        text: 'A/C signed for, client to be invoiced',
        color: 'neutral.light3',
        border: 'neutral.dark1',
        textColor: 'neutral.dark2'
      };
    }
    case FLIGHT_STATUS.INVOICE_SENT: {
      return {
        text: 'Invoice sent',
        color: 'warning.light2',
        border: 'warning.dark2',
        textColor: 'warning.dark2'
      };
    }
    case FLIGHT_STATUS.OPERATOR_PAID: {
      return {
        text: 'Operator paid',
        color: 'primary.light2',
        border: 'primary.dark1',
        textColor: 'primary.dark1'
      };
    }
    case FLIGHT_STATUS.PAYMENT_RECEIVED: {
      return {
        text: 'Payment received',
        color: 'positive.light2',
        border: 'positive.dark1',
        textColor: 'positive.dark2'
      };
    }
  }
};

export const getTotalLuggageWeight = passengers => {
  return passengers.reduce((totalWeight, passenger) => {
    const luggageWeight = passenger.customer.luggage.reduce(
      (luggageTotal, luggage) => {
        return luggageTotal + luggage.weight;
      },
      0
    );
    return totalWeight + luggageWeight;
  }, 0);
};

export const polylineConvert = (
  data: Record<string, any>,
  airportCodeConfig: string
) => {
  return {
    polyline: [
      {
        lat: parseFloat(data.depart_airport.latitude),
        lng: parseFloat(data.depart_airport.longitude)
      },
      {
        lat: parseFloat(data.arrive_airport.latitude),
        lng: parseFloat(data.arrive_airport.longitude)
      }
    ],
    arrive_airport: {
      lat: parseFloat(data.arrive_airport.latitude),
      lng: parseFloat(data.arrive_airport.longitude)
    },
    arrive_airportCode: data.arrive_airport[airportCodeConfig],
    depart_airport: {
      lat: parseFloat(data.depart_airport.latitude),
      lng: parseFloat(data.depart_airport.longitude)
    },
    depart_airportCode: data.depart_airport[airportCodeConfig]
  };
};

export const mapArrayByReference = (
  referenceArray: any[] = [],
  inputArray: any[] = []
) => {
  let result = new Array(referenceArray.length).fill(0);

  inputArray.forEach(value => {
    let index = referenceArray.indexOf(value);
    if (index !== -1) {
      result[index] = value;
    }
  });

  return result;
};

export const exportToCSV = (filename, data) => {
  const csvData = data.map(obj => {
    const user = Object.keys(obj)[0]; // Get the key (userA or userB)
    const value = obj[user]; // Get the value ('a,b,c')
    return [user, value]; // Return as an array
  });

  const csvContent =
    'data:text/csv;charset=utf-8,' + csvData.map(e => e.join(';')).join('\n'); // Join arrays to form CSV

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link); // Required for Firefox

  link.click(); // This will download the CSV file named "data.csv"
};
