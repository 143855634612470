import { userInstance } from '@instance/userInstance';
import handleActionError from '@services/handleActionError';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import router from '@utils/route/router';
import { get } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { put, takeEvery } from 'redux-saga/effects';

export function* inviteCrew(action: any): Record<string, any> {
  const { setSubmitting, closeDialog, setFieldError } = action.meta;
  try {
    const { data } = action.payload;
    setSubmitting(true);
    const response = yield userInstance.request({
      url: '/verify/invite-crew',
      method: 'POST',
      data
    });

    const returnData = get(response, 'data.data.crew');
    if (returnData) {
      yield put({
        type: '@entity/REFRESH',
        payload: {
          module: 'crew',
          data: returnData
        }
      });
      yield enqueueSnackbar(get(response, 'data.message'), {
        variant: 'success'
      });
      setSubmitting(false);
      closeDialog();
    }
  } catch (err) {
    if (get(err, 'response.status') === 409) {
      setFieldError('email', get(err, 'response.data.message'));
    } else {
      yield handleActionError(err);
    }
    setSubmitting(false);
  }
}

export function* updateCrew(action: any): Record<string, any> {
  try {
    const { data, userId } = action.payload;
    const { setSubmitting, closeDialog } = action.meta;
    setSubmitting(true);
    const response = yield userInstance.request({
      url: `/verify/crews/${userId}`,
      method: 'PUT',
      data
    });

    const returnData = get(response, 'data.data');
    if (returnData) {
      yield put({
        type: 'crew/updateEntity',
        payload: { id: userId, data: returnData }
      });
      setSubmitting(false);
      closeDialog();
      yield enqueueSnackbar(get(response, 'data.message'), {
        variant: 'success'
      });
    }
  } catch (err) {
    const { setSubmitting } = action.meta;
    setSubmitting(false);
    yield handleActionError(err);
  }
}

export function* showInviteDialog(action: any): Record<string, any> {
  yield DialogJSX.open({
    component: 'crew.dialog.inviteCrew',
    props: {
      title: 'invite_new_crew_member',
      submitButtonLabel: 'send_invite',
      cancelButtonLabel: 'close'
    },
    inMobile: 'full'
  });
}

export function* deleteCrew(action: any): Record<string, any> {
  const { id, crewName, isDetail = false } = action.payload;
  try {
    const ok = yield DialogJSX.confirm({
      props: {
        title: 'Remove crew',
        content: crewName
          ? 'are_you_sure_remove_crew_name'
          : 'are_you_sure_remove_this_crew',
        contentProps: { crewName },
        submitButtonLabel: 'yes_i_want_to_remove_this',
        cancelButtonLabel: 'close'
      },
      inMobile: 'full'
    });
    if (ok) {
      yield put({
        type: 'crew/updateEntity',
        payload: { identity: `crew.crew.${id}`, data: { deleting: true } }
      });
      const response = yield userInstance.request({
        url: `verify/crews/${id}`,
        method: 'delete'
      });
      if (response.data.status === 'success') {
        yield put({
          type: 'crew/refreshPaging'
        });
        if (isDetail) {
          router.navigate('/crews');
        }
        yield enqueueSnackbar('Crew deleted successfully!', {
          variant: 'success'
        });
      } else {
      }
    }
  } catch (err) {
    yield put({
      type: 'crew/updateEntity',
      payload: { identity: `crew.crew.${id}`, data: { deleting: false } }
    });
    yield handleActionError(err);
  }
}

const sagaEffect = [
  takeEvery('@crew/showInviteDialog', showInviteDialog),
  takeEvery('@crew/deleteCrew', deleteCrew),
  takeEvery('@crew/inviteCrew', inviteCrew),
  takeEvery('@crew/updateCrew', updateCrew)
];

export default sagaEffect;
