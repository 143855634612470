import { GlobalProvider } from '@context/GlobalContext';
import ThemeProvider from '@utils/theme/ThemeProvider';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store, { ContextSaga } from './store';
import 'dayjs/locale/en-gb';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import LocaleProvider from '@utils/locale/LocaleProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const persistor = persistStore(store);
root.render(
  <ReduxProvider store={store}>
    <GlobalProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
        <LocaleProvider>
          <PersistGate persistor={persistor}>
            <ThemeProvider>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                autoHideDuration={2000}
                maxSnack={3}
              >
                <ContextSaga>
                  <App />
                </ContextSaga>
              </SnackbarProvider>
            </ThemeProvider>
          </PersistGate>
        </LocaleProvider>
      </LocalizationProvider>
    </GlobalProvider>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
