import Cookies from 'js-cookie';
import * as React from 'react';
import JsxBackend from '../../utils/backend/JsxBackend';
import componentMap from '@utils/backend/componentMap';

export interface globalConfig {
  locale?: string;
  theme?: string;
  loaded?: boolean;
}
export type GlobalContextType = {
  config: globalConfig;
  setGlobalConfig: (config: globalConfig) => void;
  jsxBackend: any;
  pageParams: Record<string, any>;
  setPageParams: (pageParams: Record<string, any>) => void;
};

const GlobalContext = React.createContext<GlobalContextType | null>(null);

const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const jsxBackend = new JsxBackend(componentMap);
  const [config, setConfig] = React.useState<globalConfig>({
    theme: Cookies.get('MTF-theme-A4RNTX2') ?? 'default',
    locale: Cookies.get('MTF-language--A4RNTX2') ?? 'en',
    loaded: true
  });
  const [pageParams, setPageParams] = React.useState<Record<string, any>>({});

  const setGlobalConfig = (newConfig: globalConfig) => {
    setConfig({ ...config, ...newConfig });
    if (!!newConfig.locale) {
      Cookies.set('MTF-language--A4RNTX2', newConfig.locale);
    }
    if (!!newConfig.theme) {
      Cookies.set('MTF-theme-A4RNTX2', newConfig.theme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <GlobalContext.Provider
      value={{
        config: config,
        setGlobalConfig,
        jsxBackend,
        pageParams,
        setPageParams
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
function useGlobal(): GlobalContextType | null {
  return React.useContext(GlobalContext);
}

export { GlobalProvider, useGlobal, GlobalContext };
