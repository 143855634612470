import { isString } from 'lodash';
import React, { Suspense } from 'react';
import { isValidElementType } from 'react-is';

/**
 * JsxBackend provider for virtual component map by module -> type -> element type
 */
export default class JsxBackend {
  /**
   * Configuration key.
   */
  public static readonly configKey: string = 'views';

  /**
   * Store all views
   */
  private data: Record<string, any>;

  /**
   * Default constructor
   * @constructor
   */
  constructor(data: Record<string, any>) {
    this.data = data;
    this.use = this.use.bind(this);
  }

  /**
   * Extending current configuration
   *
   * @param {Record<string, any>} opts - a dictionary of Jsx Components by module -> type
   */
  public use(opts: Record<string, any>) {
    Object.keys(opts).forEach(moduleName => {
      if (!this.data[moduleName]) {
        this.data[moduleName] = {};
      }
      const moduleData = opts[moduleName];

      Object.keys(moduleData).forEach(type => {
        if (!this.data[moduleName][type]) {
          this.data[moduleName][type] = {};
        }
        // Merge the new components with existing ones
        this.data[moduleName][type] = {
          ...this.data[moduleName][type],
          ...moduleData[type]
        };
      });
    });
  }

  /**
   * Get a component by module, type, and tagName.
   *
   * @param tagName - a string like 'module.type.componentName'
   * @returns - React ElementType or undefined if not found
   */
  public get<P extends object = {}>(
    tagName: string | React.ElementType
  ): React.ComponentType<P> | undefined {
    if (isString(tagName)) {
      const [module, type, component] = tagName.split('.');

      if (module && type && component) {
        const moduleData = this.data[module];
        const typeData = moduleData ? moduleData[type] : null;
        const skeletonTagName = `${tagName}Skeleton`; //

        const ComponentFn = typeData
          ? typeData[`${module}.${type}.${component}`]
          : undefined;

        const SkeletonFn = typeData
          ? typeData[skeletonTagName] // Try to get the skeleton component
          : undefined;
        if (ComponentFn) {
          const isLazy = ComponentFn.$$typeof === Symbol.for('react.lazy');
          if (!isLazy) {
            return ComponentFn;
          }
          // Return a function that automatically handles Suspense
          return (props: P) => (
            <Suspense fallback={SkeletonFn ? <SkeletonFn /> : null}>
              <ComponentFn {...props} />
            </Suspense>
          );
        }
      }
    }
    return undefined;
  }

  /**
   * Render a component based on the provided item.
   *
   * @param tagName - component name in 'module.type.componentName' format
   * @param props - props to be passed to the component
   * @returns - JSX Element or null
   */
  public render(tagName: string, props?: any) {
    const component = this.get(tagName);

    if (component && isValidElementType(component)) {
      return React.createElement(component, props);
    }

    return null;
  }
}
