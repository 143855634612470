import { styled } from '@mui/material';

export const PocWrapper = styled('div', {
  name: 'PocWrapper'
})(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.neutral.light3
}));

export const AvatarWrapper = styled('div', { name: 'AvatarWrapper' })(
  ({ theme }) => ({
    backgroundColor: theme.palette.neutral.light3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative'
  })
);

export const Badge = styled('div', { name: 'Badge' })(({ theme }) => ({
  position: 'absolute',
  textTransform: 'uppercase',
  fontFamily: 'Open Sans',
  backgroundColor: theme.palette.primary.dark1,
  padding: '4px',
  bottom: '0px',
  fontSize: '13px',
  fontWeight: '600',
  lineHeight: '9px',
  letterSpacing: '0.5px',
  textAlign: 'center',
  color: theme.palette.primary.light3,
  borderRadius: '16px'
}));
