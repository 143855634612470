import Icon from '@common/components/Icon';
import {
  formatPhone,
  joinCatering,
  tooltipPassportStatusData
} from '@helper/stringFunction';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import { useTranslation } from 'react-i18next';
import {
  ClientInfo,
  ClientName,
  EditButtonWrapper,
  SectionTitle
} from './styled';

export default function AccountInfoBlock({ loading, clientData }) {
  const {
    firstName,
    lastName,
    onfido_image,
    email,
    status,
    passport_number,
    date_of_birth,
    mobile,
    nationality,
    allergies,
    caterings,
    passport_expiry_date,
    sex,
    pax_weight,
    activated,
    id,
    phone_country_code,
    place_of_birth
  } = clientData;
  const { t } = useTranslation();
  const placeholder = loading ? (
    <Skeleton variant="text" width={80} />
  ) : (
    <Typography variant="body2" color="neutral.main">
      <i>{t('no_data')}</i>
    </Typography>
  );
  const clientName =
    firstName || lastName ? `${firstName} ${lastName}`.trim() : '';

  const onEdit = () => {
    DialogJSX.open({
      component: 'client.dialog.editClient',
      props: {
        title: 'edit_client_detail',
        submitButtonLabel: 'save_changes',
        cancelButtonLabel: 'cancel',
        userId: id
      },
      inMobile: 'full'
    });
  };
  return (
    <ClientInfo>
      <EditButtonWrapper>
        <Button
          disabled={!activated}
          onClick={onEdit}
          variant="outlined"
          color="inherit"
          startIcon={
            <Icon icon="icon-Pencil-filled" sx={{ color: 'neutral.dark1' }} />
          }
        >
          {t('edit')}
        </Button>
      </EditButtonWrapper>
      <Avatar
        className="fs-exclude"
        sx={{
          width: 96,
          height: 96
        }}
        src={onfido_image}
      />
      <ClientName>
        {clientName.length ? (
          clientName.toLowerCase()
        ) : loading ? (
          <Skeleton variant="text" width={80} />
        ) : (
          ''
        )}
        {sex === 'F' ? (
          <Icon
            icon="icon-female-gender"
            size="sm"
            sx={{ color: 'accent.light1' }}
          />
        ) : null}
        {sex === 'M' ? (
          <Icon
            icon="icon-male-gender"
            size="sm"
            sx={{ color: 'primary.main' }}
          />
        ) : null}
      </ClientName>
      <Typography
        component="div"
        sx={{ mt: 0.5 }}
        id="client-email"
        variant="body2"
        color="neutral.dark1"
      >
        {email ?? placeholder}
        {pax_weight > 0 ? ` | ${pax_weight}kg` : null}
      </Typography>
      <Box
        sx={{
          backgroundColor: 'neutral.light3',
          p: 1,
          borderRadius: 2,
          mt: 2,
          gap: 1
        }}
        className="flex-center-rows"
      >
        <Tooltip title={t(tooltipPassportStatusData(status ?? 1).text)} arrow>
          {loading && !status ? (
            <Skeleton variant="circular" width={32} height={32} />
          ) : (
            <Icon
              icon={tooltipPassportStatusData(status ?? 1).icon}
              size="md"
              color={tooltipPassportStatusData(status ?? 1).color}
              haveBackground
              sx={{ border: '1px solid rgb(0,0,0,0.06)' }}
            />
          )}
        </Tooltip>
        <Typography variant="body2" color="neutral.dark3">
          {passport_number ?? ''}
        </Typography>
        {passport_expiry_date ? (
          <Typography variant="caption" color="neutral.dark2">
            {t('expires_on_passport_date', { passport_expiry_date })}
          </Typography>
        ) : null}
      </Box>
      <Box sx={{ mt: 3 }} className="flex-center-rows">
        <Icon
          icon="icon-cake-birthday-filled"
          size="sm"
          sx={{ color: 'primary.main' }}
        />
        <Typography
          sx={{ ml: 1 }}
          id="client-email"
          variant="body2"
          color="neutral.dark3"
        >
          {date_of_birth ?? placeholder}
          {place_of_birth && place_of_birth.length > 0
            ? ` | ${place_of_birth}`
            : null}
        </Typography>
      </Box>
      <Box sx={{ mt: 1.5 }} className="flex-center-rows">
        <Icon
          icon="icon-Phone-filled"
          size="sm"
          sx={{ color: 'primary.main' }}
        />
        <Typography
          sx={{ ml: 1 }}
          id="client-email"
          variant="body2"
          color="neutral.dark3"
        >
          {mobile
            ? `+${phone_country_code.phoneCode} ${formatPhone(mobile)}`
            : placeholder}
        </Typography>
      </Box>
      <Box
        sx={{ mt: 1.5, alignItems: 'flex-start' }}
        className="flex-center-rows"
      >
        <Icon
          icon="icon-Globe-filled"
          size="sm"
          sx={{ color: 'primary.main', paddingTop: '3px' }}
        />
        <Typography
          sx={{ ml: 1 }}
          id="client-email"
          variant="body2"
          color="neutral.dark3"
        >
          {nationality?.label ?? placeholder}
        </Typography>
      </Box>
      <SectionTitle sx={{ mt: 3 }}>{t('allergies')}</SectionTitle>
      <Stack
        sx={{ mt: 1 }}
        direction="row"
        useFlexGap
        flexWrap="wrap"
        spacing={1}
      >
        {loading && !allergies ? (
          <Skeleton variant="rounded" width={100} height={28} />
        ) : allergies && allergies.length ? (
          allergies.map((item: Record<string, any>, index: number) => {
            return <Chip label={item.name} key={index} color="warning" />;
          })
        ) : (
          placeholder
        )}
      </Stack>
      <SectionTitle sx={{ mt: 3 }}>{t('catering')}</SectionTitle>
      <Box
        sx={{ mt: 1, alignItems: 'flex-start' }}
        className="flex-center-rows"
      >
        <Icon
          icon="icon-catering-2"
          size="sm"
          sx={{ color: 'primary.main', paddingTop: '3px' }}
        />
        <Typography
          sx={{ ml: 1 }}
          id="client-email"
          variant="body2"
          color="neutral.dark3"
        >
          {joinCatering(caterings) ?? placeholder}
        </Typography>
      </Box>
    </ClientInfo>
  );
}
