import Breadcrumbs from '@common/components/Breadcrumbs';
import { Header } from './styled';
import { useTranslation } from 'react-i18next';

export default function CrewDetailHeader() {
  const { t } = useTranslation();
  return (
    <Header>
      <Breadcrumbs
        data={[
          { to: '/crews', label: t('crew') },
          { label: t('crew_details') }
        ]}
      />
    </Header>
  );
}
