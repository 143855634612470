import Icon from '@common/components/Icon';
import { SectionWrapper } from '@flight/pages/flight-detail/styled';
import { tooltipFlightStatusData } from '@helper/stringFunction';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const InvoiceSection = ({ flight_status }) => {
  const { t } = useTranslation();
  if (!flight_status) return null;
  const statusData = tooltipFlightStatusData(flight_status);
  return (
    <SectionWrapper>
      <Box className="flex-center-rows" sx={{ gap: 1, mb: 4 }}>
        <Icon icon="icon-invoice" size="lg" sx={{ color: 'primary.dark1' }} />
        <Typography variant="head3Mons" color="primary.dark2">
          {t('invoice')}
        </Typography>
      </Box>
      <Box
        className="flex-center-columns"
        sx={{
          p: 4,
          backgroundColor: 'neutral.light3',
          width: 'fit-content',
          gap: 1,
          borderRadius: '8px'
        }}
      >
        <img src="/images/invoice.png" alt="" />
        <Box
          sx={{
            px: 2,
            py: 0.75,
            mb: 0.5,
            backgroundColor: statusData.color,
            borderRadius: 4,
            border: `1px solid`,
            borderColor: statusData.border,
            color: statusData.textColor,
            lineHeight: '14.63px'
          }}
        >
          <Typography variant="head5Mons">{statusData.text}</Typography>
        </Box>
      </Box>
    </SectionWrapper>
  );
};

export default InvoiceSection;
