import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';
import en from './en.json';
import vi from './vi.json';

const Init18n = (selectedLocale: string) => {
  return i18n
    .use(ICU)
    .use(initReactI18next)
    .init({
      lng: selectedLocale,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false
      },
      resources: {
        en: { translation: en },
        vi: { translation: vi }
      },
      react: {
        useSuspense: true,
        transKeepBasicHtmlNodesFor: ['b', 'i', 'strong', 'br', 'span']
      }
    });
};

export { i18n, Init18n };
