import Select from '@common/components/Select';
import { FormControl, FormLabel, MenuItem } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

const QUESTION_LIST = [
  {
    value: 'What is the name of a college you applied to but didn’t attend?',
    label: 'What is the name of a college you applied to but didn’t attend?'
  },
  {
    value: 'What was the name of the first school you remember attending?',
    label: 'What was the name of the first school you remember attending?'
  },
  {
    value:
      'Where was the destination of your most memorable school field trip?',
    label: 'Where was the destination of your most memorable school field trip?'
  },
  {
    value: "What was your maths teacher's surname in your 8th year of school?",
    label: "What was your maths teacher's surname in your 8th year of school?"
  },
  {
    value: 'What was the name of your first stuffed toy?',
    label: 'What was the name of your first stuffed toy?'
  },
  {
    value: "What was your driving instructor's first name?",
    label: "What was your driving instructor's first name?"
  },
  {
    value: 'What was your childhood nickname?',
    label: 'What was your childhood nickname?'
  },
  {
    value: 'What is the first company you owned shares in?',
    label: 'What is the first company you owned shares in?'
  },
  {
    value: 'What was the name of your first pet?',
    label: 'What was the name of your first pet?'
  },
  {
    value: 'What was the name of the city you first flew into?',
    label: 'What was the name of the city you first flew into?'
  },
  {
    value: 'What is the street number of the house you grew up in?',
    label: 'What is the street number of the house you grew up in?'
  },
  {
    value: 'What was the name of your first stuffed animal?',
    label: 'What was the name of your first stuffed animal?'
  },
  {
    value: 'What school did you attend for sixth grade?',
    label: 'What school did you attend for sixth grade?'
  },
  {
    value: "What is your maternal grandmother's maiden name?",
    label: "What is your maternal grandmother's maiden name?"
  },
  {
    value: 'In what city or town did your mother and father meet?',
    label: 'In what city or town did your mother and father meet?'
  },
  {
    value:
      'What was your childhood phone number including area code? (e.g., 000-000-0000)',
    label:
      'What was your childhood phone number including area code? (e.g., 000-000-0000)'
  },
  {
    value:
      'What is your youngest brother’s birthday month and year? (e.g., January 1900)',
    label:
      'What is your youngest brother’s birthday month and year? (e.g., January 1900)'
  },
  {
    value: 'In what city or town was your first job?',
    label: 'In what city or town was your first job?'
  },
  {
    value: 'In what city does your nearest sibling live?',
    label: 'In what city does your nearest sibling live?'
  },
  {
    value: 'In what city did you meet your spouse/significant other?',
    label: 'In what city did you meet your spouse/significant other?'
  }
];

const SecureQuestionForm = ({ config, name, formik }: any) => {
  const { t } = useTranslation();
  const [field, meta] = useField(name ?? 'TextField');
  const [focus, setFocus] = React.useState<boolean>(false);
  const {
    label,
    disabled,
    sx,
    placeholder,
    fullWidth = true,
    size = 'small',
    required,
    autoFocus,
    maxLength,
    toUpperCase,
    onClick
  } = config;

  // fix: A component is changing an uncontrolled input
  if (!field.value) {
    field.value = config.defaultValue ?? '';
  }
  if (toUpperCase) {
    field.value = field.value.toUpperCase();
  }
  const haveError: boolean = meta.touched && Boolean(meta.error);
  const onBlur = (e: any) => {
    field.onBlur(e);
    setFocus(false);
  };
  const onClickFocus = (e: any) => {
    onClick && onClick();
    setFocus(true);
  };
  const props = {
    ...field,
    onBlur,
    onClick: onClickFocus,
    disabled: disabled,
    required,
    autoFocus,
    size,
    inputProps: {
      maxLength
    },
    placeholder,
    error: haveError && !focus,
    fullWidth,
    sx
  };
  return (
    <FormControl fullWidth>
      <FormLabel
        sx={{ mb: 0.5, fontSize: 14, color: 'primary.dark3' }}
        required
      >
        {label}
      </FormLabel>

      <Select
        {...props}
        labelId="select-label"
        MenuProps={{ PaperProps: { sx: { maxHeight: 264 } } }}
        displayEmpty
        renderValue={(selected: any) => {
          return (
            <>{selected.length ? selected : t('select_a_secure_question')}</>
          );
        }}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {QUESTION_LIST.map((item, index) => {
          return (
            <MenuItem
              sx={{ whiteSpace: 'normal' }}
              key={index}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
      {!focus && meta.error && meta.touched ? (
        <div className="errorMessage">{t(meta.error)}</div>
      ) : null}
    </FormControl>
  );
};

export default SecureQuestionForm;
