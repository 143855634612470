import { Skeleton, Tab, Tabs, Typography } from '@mui/material';
import { Formik } from 'formik';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Identify from './Identify';
import Medical from './Medical';
import PilotLicense from './PilotLicense';
import { CrewContent } from './styled';

export default function Content({ data, loading }) {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <CrewContent>
      <Tabs onChange={handleChange} value={tabValue}>
        <Tab disableRipple label={t('identity_check_information')} value={0} />
        <Tab disableRipple label={t('pilot_licence_information')} value={1} />
        <Tab disableRipple label={t('medical_doc_information')} value={2} />
      </Tabs>
      {loading ? (
        <Skeleton />
      ) : data.status === 1 ? (
        <Typography
          variant="body1"
          color="neutral.dark2"
          component="div"
          sx={{ mb: 1 }}
        >
          {t('when_crew_accepted_information_be_here')}
        </Typography>
      ) : (
        <Formik
          initialValues={data}
          onSubmit={() => {
            //
          }}
        >
          {({ initialValues }) => (
            <Fragment>
              {tabValue === 0 && <Identify initialValues={initialValues} />}
              {tabValue === 1 && <PilotLicense initialValues={initialValues} />}
              {tabValue === 2 && <Medical initialValues={initialValues} />}
            </Fragment>
          )}
        </Formik>
      )}
    </CrewContent>
  );
}
