import BlockList from '@common/block/BlockList';
import { CREWS_PER_PAGE } from '@constant/constant';
import { CrewTableHeader } from '@crew/components/crew-list';
import { Box } from '@mui/material';
import { CrewListWrapper } from './styled';

const CrewList = () => {

  return (
    <>
      <CrewTableHeader />
      <Box sx={{ overflow: 'auto', pr: 8, ml: -1, pl: 1 }}>
        <CrewListWrapper>
          <BlockList
            itemPerPage={CREWS_PER_PAGE}
            itemName="crew.item.crewListItem"
            emptyName="crew.block.crewListEmpty"
            module="crew"
            resource="crew"
            apiUrl="/verify/crews"
            hasPagination={true}
          />
        </CrewListWrapper>
      </Box>
    </>
  );
};

export default CrewList;
