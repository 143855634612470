import Icon from '@common/components/Icon';
import { Box, Tooltip, Typography } from '@mui/material';
import useAirportCode from 'src/hooks/useAirportCode';
import useTimezone from 'src/hooks/useTimezone';
import { FlightLine } from './styled';
import { useTranslation } from 'react-i18next';

export default function Leg({ item }: { item: Record<string, any> }) {
  const {
    depart_airport,
    arrive_airport,
    departTime,
    departTimeUTC,
    arriveTime,
    arriveTimeUTC,
    departDateShort,
    departDateUTC,
    arriveDateShort,
    arriveDateUTC,
    paxTotal,
    paxAmount,
    cateringAmount,
    cateringTotal,
    hotelComplete,
    transportComplete
  } = item;

  const { t } = useTranslation();

  const airportCodeConfig = useAirportCode();
  const timezone = useTimezone();

  const depart_date = timezone === 'utc' ? departDateUTC : departDateShort;
  const arrive_date = timezone === 'utc' ? arriveDateUTC : arriveDateShort;
  const depart_time = timezone === 'utc' ? departTimeUTC : departTime;
  const arrive_time = timezone === 'utc' ? arriveTimeUTC : arriveTime;

  const returnColor = (
    amount: number,
    total: number,
    primaryCondition?: boolean
  ) => {
    if (amount > 0 || primaryCondition) {
      if (amount === total || primaryCondition) {
        return 'positive.dark1';
      } else {
        return 'warning.dark1';
      }
    } else {
      return 'neutral.dark2';
    }
  };
  return (
    <Box
      className="flex-space-between-row"
      sx={{
        width: '100%',
        p: 2,
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '35%',
          minWidth: '300px',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: 1,
          borderRight: '1px solid #CCD4DC'
        }}
      >
        <Box className="flex-column" sx={{ alignItems: 'flex-end', flex: 1 }}>
          <Tooltip title={depart_airport.name} arrow>
            <Typography
              variant="head4Mons"
              component="div"
              sx={{ mb: 1 }}
              color="neutral.dark3"
            >
              {depart_airport?.[airportCodeConfig]}
            </Typography>
          </Tooltip>

          <Typography variant="body1" color="neutral.dark3">
            {depart_time ?? 'TBD'}
          </Typography>
          <Typography variant="caption" color="neutral.dark1">
            {depart_date ?? 'TBD'}
          </Typography>
        </Box>
        <Box className="flex-center-rows" sx={{ gap: 1 }}>
          <FlightLine />
          <Icon
            icon="icon-airplane-above-fill icon-rotate-90"
            sx={{ color: 'neutral.main' }}
          />
          <FlightLine />
        </Box>

        <Box className="flex-column" sx={{ alignItems: 'flex-start', flex: 1 }}>
          <Tooltip title={arrive_airport.name} arrow>
            <Typography
              variant="head4Mons"
              component="div"
              sx={{ mb: 1 }}
              color="neutral.dark3"
            >
              {arrive_airport?.[airportCodeConfig]}
            </Typography>
          </Tooltip>

          <Typography variant="body1" color="neutral.dark3">
            {arrive_time ?? 'TBD'}
          </Typography>
          <Typography variant="caption" color="neutral.dark1">
            {arrive_date ?? 'TBD'}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          px: 2,
          flex: 1,
          justifyContent: 'space-around'
        }}
      >
        <Box className="flex-center-columns" sx={{ minWidth: '80px' }}>
          <Icon
            icon="icon-passport-bold"
            sx={{ color: returnColor(paxAmount, paxTotal), mb: 0.5 }}
          />
          <Typography variant="caption" color="neutral.dark3">
            {`${paxAmount}/${paxTotal}`}
          </Typography>
          <Typography variant="caption" color="neutral.dark2">
            {t('passport')}
          </Typography>
        </Box>
        <Box className="flex-center-columns" sx={{ minWidth: '80px' }}>
          <Icon
            icon="icon-catering-2"
            sx={{ color: returnColor(cateringAmount, cateringTotal), mb: 0.5 }}
          />
          <Typography variant="caption" color="neutral.dark3">
            {`${cateringAmount}/${cateringTotal}`}
          </Typography>
          <Typography variant="caption" color="neutral.dark2">
            {t('catering')}
          </Typography>
        </Box>
        <Box className="flex-center-columns" sx={{ minWidth: '80px' }}>
          <Icon
            icon="icon-Hotel"
            sx={{
              color: returnColor(null, null, hotelComplete),
              mb: 0.5
            }}
          />
          <Typography variant="caption" color="neutral.dark3">
            {hotelComplete ? t('data_added') : t('no_data')}
          </Typography>
          <Typography variant="caption" color="neutral.dark2">
            {t('hotels')}
          </Typography>
        </Box>
        <Box className="flex-center-columns" sx={{ minWidth: '80px' }}>
          <Icon
            icon="icon-car-fill"
            sx={{
              color: returnColor(null, null, transportComplete),
              mb: 0.5
            }}
          />
          <Typography variant="caption" color="neutral.dark3">
            {transportComplete ? t('data_added') : t('no_data')}
          </Typography>
          <Typography variant="caption" color="neutral.dark2">
            {t('transport')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
