import Icon from '@common/components/Icon';
import { USER_LEVEL } from '@constant/user_level';
import { useFlightDetail } from '@flight/context/flightDetailContext';
import { getTotalLuggageWeight } from '@helper/stringFunction';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { userId, userLevelSelector } from '@user/selector/userInfoSelector';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import AuthorizedComponent from 'src/hoc/AuthorizedComponent';
import {
  Column,
  ContentWrapper,
  Divider,
  IconWrapper,
  StaticWrapper
} from './styled';

const PassengerActionHeader = ({ passenger, loading }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { activeLeg, legData, flightData } = useFlightDetail();
  const { customer: mainPoc, customer_2: secondPoc } = flightData;
  const userLevel = useSelector(userLevelSelector);

  const { id: flightLineId } = legData;
  const totalLuggage = getTotalLuggageWeight(passenger);
  const cateringSelected = passenger.filter(
    item => item?.customer?.caterings?.length > 0
  );
  const meInList = passenger.find(
    (item: Record<string, any>) => item?.customer?.is_me
  )?.customer;
  const dispatch = useDispatch();
  const myId = useSelector(userId);
  const isMyLeg = [mainPoc?.id, secondPoc?.id].includes(myId);

  // ACTION BUTTON
  const onRemind = () => {
    DialogJSX.comingsoon();
  };
  const onAddPax = () => {
    DialogJSX.open({
      component: 'flight.dialog.addPax',
      props: {
        title: 'add_passenger',
        submitButtonLabel: 'confirm',
        cancelButtonLabel: 'close',
        flightId: id
      },
      inMobile: 'full'
    });
  };

  const addYourself = () => {
    dispatch({
      type: '@flight/addYourself',
      payload: { flightLineId, activeLeg }
    });
  };

  const addMyCatering = () => {
    DialogJSX.open({
      component: 'flight.dialog.addCatering',
      props: {
        title: 'add_my_catering',
        cancelButtonLabel: 'close',
        submitButtonLabel: 'confirm',
        activeLeg,
        flightId: id,
        meInList
      },
      inMobile: 'full'
    });
  };

  const addMyLuggage = () => {
    DialogJSX.open({
      component: 'flight.dialog.editLuggage',
      props: {
        title: 'add_luggage',
        cancelButtonLabel: 'close',
        paxId: passenger.find(
          (item: Record<string, any>) => item?.customer?.is_me
        ).id
      },
      inMobile: 'full'
    });
  };

  const onViewCatering = () => {
    DialogJSX.open({
      component: 'flight.dialog.passengerCatering',
      props: {
        title: 'passenger_catering_choices_leg_n',
        titleProps: { activeLeg: activeLeg + 1 },
        cancelButtonLabel: 'close',
        passenger
      },
      inMobile: 'full'
    });
  };

  const onViewLuggage = () => {
    DialogJSX.open({
      component: 'flight.dialog.passengerLuggage',
      props: {
        title: 'passenger_luggage_leg_n',
        titleProps: { activeLeg: activeLeg + 1 },
        cancelButtonLabel: 'close',
        passenger,
        totalLuggage
      },
      inMobile: 'full'
    });
  };

  return (
    <>
      <Box className="flex-center-rows" sx={{ gap: 1, mb: 1 }}>
        <Icon
          icon="icon-address-book-tabs-fill"
          size="lg"
          sx={{ color: 'primary.dark1' }}
        />
        <Typography variant="head3Mons" color="primary.dark2">
          {isMyLeg || userLevel !== USER_LEVEL.CLIENT
            ? t('passenger_list')
            : t('my_travel_options')}
        </Typography>
        {loading ? null : (
          <Box
            className="flex-center-rows"
            sx={{ flex: 1, justifyContent: 'flex-end', gap: 2 }}
          >
            <AuthorizedComponent
              requiredRoles={[USER_LEVEL.BROKER, USER_LEVEL.SUPERUSER]}
            >
              {passenger?.length ? (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  startIcon={
                    <Icon icon="icon-remind" sx={{ color: 'primary.dark2' }} />
                  }
                  onClick={onRemind}
                >
                  {t('remind_pax_enter_details')}
                </Button>
              ) : null}
            </AuthorizedComponent>
            <AuthorizedComponent requiredRoles={[USER_LEVEL.CLIENT]}>
              {meInList &&
              !meInList?.caterings?.length &&
              legData?.cateringOptions?.length ? (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  startIcon={
                    <Icon
                      icon="icon-catering-2"
                      sx={{ color: 'primary.dark2' }}
                      size="sm"
                    />
                  }
                  onClick={addMyCatering}
                >
                  {t('add_my_catering')}
                </Button>
              ) : null}
              {!meInList || meInList?.luggage?.length ? null : (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  startIcon={
                    <Icon
                      icon="icon-Briefcase-filled"
                      sx={{ color: 'primary.dark2' }}
                    />
                  }
                  onClick={addMyLuggage}
                >
                  {t('add_my_luggage')}
                </Button>
              )}
              {meInList ? null : (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  startIcon={
                    <Icon
                      icon="icon-Plus-filled"
                      sx={{ color: 'primary.dark2' }}
                    />
                  }
                  onClick={addYourself}
                >
                  {t('add_yourself_to_leg')}
                </Button>
              )}
              {meInList && meInList.luggage.length ? (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  startIcon={
                    <Icon
                      icon="icon-printer-fill"
                      sx={{ color: 'primary.dark2' }}
                    />
                  }
                  onClick={onRemind}
                >
                  {t('print_luggage_tag')}
                </Button>
              ) : null}
            </AuthorizedComponent>
          </Box>
        )}
      </Box>
      {isMyLeg || userLevel !== USER_LEVEL.CLIENT ? (
        <ContentWrapper>
          <StaticWrapper>
            <Column>
              <IconWrapper
                sx={{
                  backgroundColor: 'neutral.white'
                }}
              >
                <Icon
                  size="lg"
                  icon="icon-User-group-filled"
                  sx={{ color: 'primary.light1' }}
                />
              </IconWrapper>
              <Box
                className="flex-column"
                sx={{ alignItems: 'flex-start', flex: 1 }}
              >
                <Typography variant="subtitle2" color="neutral.dark3">
                  {t('number_of_pax')}
                </Typography>
                <Typography variant="subtitle1" color="primary.dark1">
                  <b>{passenger?.length}</b>
                </Typography>
              </Box>
              <Box className="flex-column" sx={{ alignItems: 'flex-end' }}>
                <IconButton color="inherit" onClick={onAddPax}>
                  <Icon icon="icon-Plus-filled" size="sm"></Icon>
                </IconButton>
              </Box>
            </Column>
            <Divider />
            <Column>
              <IconWrapper
                sx={{
                  backgroundColor: 'neutral.white'
                }}
              >
                <Icon
                  size="lg"
                  icon="icon-Briefcase-filled"
                  sx={{ color: 'primary.light1' }}
                />
              </IconWrapper>
              <Box
                className="flex-column"
                sx={{ alignItems: 'flex-start', flex: 1 }}
              >
                <Typography variant="subtitle2" color="neutral.dark3">
                  {t('total_luggage_weight')}
                </Typography>
                <Typography variant="subtitle1" color="primary.dark1">
                  <b>{totalLuggage} kg</b>
                </Typography>
              </Box>
              {totalLuggage > 0 ? (
                <Box className="flex-column" sx={{ alignItems: 'flex-end' }}>
                  <IconButton color="inherit" onClick={onViewLuggage}>
                    <Icon icon="icon-eye-view" size="sm"></Icon>
                  </IconButton>
                </Box>
              ) : null}
            </Column>
            <Divider />
            <Column>
              <IconWrapper
                sx={{
                  backgroundColor: 'neutral.white'
                }}
              >
                <Icon
                  icon="icon-catering-2"
                  size="lg"
                  sx={{ color: 'primary.light1' }}
                />
              </IconWrapper>
              <Box
                className="flex-column"
                sx={{ alignItems: 'flex-start', flex: 1 }}
              >
                <Typography variant="subtitle2" color="neutral.dark3">
                  {t('catering_added')}
                </Typography>
                <Typography variant="subtitle1" color="primary.dark1">
                  <b>
                    {passenger.length
                      ? `${cateringSelected.length}/${passenger.length}`
                      : '0'}
                  </b>
                </Typography>
              </Box>
              {cateringSelected.length ? (
                <Box className="flex-column" sx={{ alignItems: 'flex-end' }}>
                  <IconButton color="inherit" onClick={onViewCatering}>
                    <Icon icon="icon-eye-view" size="sm"></Icon>
                  </IconButton>
                </Box>
              ) : null}
            </Column>
          </StaticWrapper>
        </ContentWrapper>
      ) : null}
    </>
  );
};

export default PassengerActionHeader;
