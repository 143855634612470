import Icon from '@common/components/Icon';
import { CheckboxForm } from '@common/formElement';
import { useRegister } from '@context/RegisterContext';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { Content, FooterAction } from './_styled';
import { useDispatch } from 'react-redux';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const ALLERGIES_LIST = [
  { value: 1, label: 'Celery' },
  { value: 2, label: 'Gluten' },
  { value: 3, label: 'Crustaceans' },
  { value: 4, label: 'Eggs' },
  { value: 5, label: 'Fish' },
  { value: 6, label: 'Lupin' },
  { value: 7, label: 'Milk' },
  { value: 8, label: 'Mollusca' },
  { value: 9, label: 'Mustard' },
  { value: 10, label: 'Tree nuts' },
  { value: 11, label: 'Sesame seeds' },
  { value: 12, label: 'Peanuts' },
  { value: 13, label: 'Soybeans' }
];

export default function Allergies() {
  const { t } = useTranslation();
  const { backStep, nextStep, token, userDetail, setUserDetail } =
    useRegister();
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const handleSubmit = async (value: any, formik: FormikHelpers<any>) => {
    if (!formRef.current.dirty) {
      nextStep();
      return;
    }
    const result = [];
    for (const [key, val] of Object.entries(value)) {
      if (val === 1) {
        result.push(parseInt(key));
      }
    }
    dispatch({
      type: '@register/submitAllergies',
      payload: {
        value: result,
        token,
        nextStep,
        setUserDetail
      }
    });
  };

  const initValue = () => {
    const result = {};
    userDetail?.allergies?.map(item => (result[item] = 1));
    return result;
  };

  return (
    <Box style={{ height: '100%' }} className="flex-space-between-column">
      <Typography
        sx={{ mt: { xs: 1, md: 4 } }}
        component="div"
        variant="body2"
        color="neutral.dark3"
      >
        {t('we_offered_catering_based_on_your_allergy')}
      </Typography>
      <Box sx={{ mt: 3, flex: 1 }}>
        <Formik
          initialValues={{ ...initValue() }}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {props => (
            <Form
              style={{ height: '100%' }}
              className="flex-space-between-column"
            >
              <Content>
                <Grid container rowSpacing={3} columnSpacing={1}>
                  {ALLERGIES_LIST.map((item, index) => {
                    return (
                      <Grid key={index} item xs={6} md={3}>
                        <CheckboxForm
                          name={item.value}
                          config={{
                            size: 'small',
                            label: item.label,
                            required: true
                          }}
                          initialValues={props.initialValues}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Content>
              <FooterAction>
                <Button
                  onClick={backStep}
                  size="large"
                  variant="outlined"
                  color="inherit"
                  startIcon={<Icon icon="icon-previous-arrow" />}
                >
                  {t('previous_step')}
                </Button>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={
                    <Icon
                      icon="icon-previous-arrow"
                      className="icon-flip-x"
                      color="lightAccent"
                    />
                  }
                >
                  {t('next_step')}
                </Button>
              </FooterAction>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
