import { PASSWORD_REGEX } from '@constant/regex';
import * as Yup from 'yup';

export const ConfirmDetailSchema = Yup.object().shape({
  firstName: Yup.string().required('first_name_is_required'),
  lastName: Yup.string().required('last_name_is_required'),
  email: Yup.string().email('invalid_email').required('email_is_required'),
  password: Yup.string()
    .required('please_input_password')
    .matches(PASSWORD_REGEX, 'input_password_regex_validation'),
  confirmPassword: Yup.string().when('password', (password, field) =>
    password
      ? field
          .required('please_confirm_password')
          .oneOf([Yup.ref('password')], 'confirm_password_not_match')
      : field
  )
});
