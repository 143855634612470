import Icon from '@common/components/Icon';
import { Box, Button, Typography } from '@mui/material';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import IdealLegItem from './IdealLegItem';

export default function AccountIdealLeg({ loading, emptyLegs = [] }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const scrollRef = useRef();

  const addRoute = () => {
    DialogJSX.open({
      component: 'client.dialog.addRoute',
      props: {
        title: 'add_a_new_route',
        submitButtonLabel: 'add_route',
        cancelButtonLabel: 'cancel',
        id: id
      },
      inMobile: 'full'
    });
  };

  return (
    <Box
      sx={{ mt: 3, pr: 8, overflow: 'auto', height: '100%' }}
      ref={scrollRef}
    >
      {emptyLegs.length > 0 ? (
        emptyLegs.map((item, index) => {
          return <IdealLegItem key={index} data={item} user_id={id} />;
        })
      ) : (
        <Typography
          variant="body2"
          color="neutral.dark1"
          component="div"
          sx={{ pb: 1 }}
        >
          <i>
            <Trans i18nKey="ideal_empty_legs_of_client_placeholder" />
          </i>
        </Typography>
      )}
      <Box sx={{ mt: 3, mb: 2, pb: 4 }} className="flex-center-rows">
        <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={{ mr: 3 }}
          disabled={emptyLegs.length === 10}
          onClick={addRoute}
          startIcon={<Icon icon="icon-plus" color="white" />}
        >
          {t('add_a_route')}
        </Button>
        <Typography variant="body2" color="neutral.dark1">
          {t('n_10_remaining_legs', { leg: 10 - emptyLegs.length })}
        </Typography>
      </Box>
    </Box>
  );
}
