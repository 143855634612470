import { DialogTitle, IconButton, Typography } from '@mui/material';
import { DialogHeader } from './BaseDialog.styled';
import { Trans } from 'react-i18next';
import Icon from '@common/components/Icon';

export default function Header(props: any) {
  const { title, titleProps = {}, onClose } = props;

  return (
    <>
      <DialogTitle>
        <DialogHeader>
          <Typography variant="h6" color="neutral.dark3">
            <Trans i18nKey={title} values={{ ...titleProps }} />
          </Typography>
          <IconButton sx={{ p: 1, mr: -1 }} onClick={onClose}>
            <Icon size="sm" icon="icon-close" color="black" />
          </IconButton>
        </DialogHeader>
      </DialogTitle>
    </>
  );
}
