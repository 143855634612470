import { useGlobal } from '@context/GlobalContext';
import { Dialog, DialogContent, useMediaQuery, useTheme } from '@mui/material';
import React, { ElementType, useCallback, useRef, useState } from 'react';
import Header from './DialogHeader';

export type propsDialogComponent = {
  component?: ElementType | string;
  props?: Record<string, any>;
  closable?: boolean;
  onClose?: () => void;
  id?: number;
  closeDialog?: (props?: any) => void;
  width?: number;
  theme?: 'black' | 'white';
  title?: string;
  closeable?: boolean;
  closeIcon?: boolean;
  onSubmit?: () => void;
  type?: 'normal' | 'failure';
  padding?: Record<string, any>;
  inMobile?: 'full' | 'bottom';
  resolve: any;
};

export const BaseDialog = React.forwardRef(
  (propsValues: propsDialogComponent, ref) => {
    const {
      component,
      props,
      closeDialog = () => {},
      inMobile,
      resolve
    } = propsValues;
    const { closeable = true, titleProps = {}, title } = props;
    const { jsxBackend } = useGlobal();
    const [forceOpen, setForceOpen] = useState(false);

    const valueRef = useRef(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const setValue = useCallback((value: boolean) => {
      valueRef.current = value;
    }, []);
    const onClose = () => {
      if (!closeable || forceOpen) return;
      resolve(valueRef.current);
      closeDialog();
    };
    const RenderInner =
      typeof component === 'string' ? jsxBackend.get(component) : component;

    const DialogContentWrapper = title ? DialogContent : React.Fragment;
    return (
      <Dialog
        open={true}
        onClose={onClose}
        fullScreen={fullScreen && inMobile === 'full'}
      >
        {title ? (
          <Header title={title} titleProps={titleProps} onClose={onClose} />
        ) : null}
        <DialogContentWrapper>
          {RenderInner && (
            <RenderInner
              inDialog={true}
              closeDialog={onClose}
              setValue={(value: boolean) => setValue(value)}
              setForceOpen={setForceOpen}
              value={valueRef.current}
              {...props}
            />
          )}
        </DialogContentWrapper>
      </Dialog>
    );
  }
);
