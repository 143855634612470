import { styled } from '@mui/material';

export const CrewBanner = styled('div', { name: 'CrewBanner' })(
  ({ theme }) => ({
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3)
  })
);

export const CrewBannerLeft = styled('div', { name: 'CewBannerLeft' })(() => ({
  alignItems: 'center',
  gap: '32px',
  flex: 1
}));

export const CrewBannerDivider = styled('div', { name: 'CrewBannerDivider' })(
  ({ theme }) => ({
    margin: '0px 24px',
    background: theme.palette.neutral.light1,
    width: '1px',
    height: '22px'
  })
);

export const CrewBannerRight = styled('div', { name: 'CrewBannerRight' })(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.positive.dark1}`,
    color: theme.palette.positive.dark1,
    background: theme.palette.positive.light3,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    alignItems: 'center',
    display: 'flex',
    gap: '4px',
    textWrap: 'nowrap'
  })
);
