import AirportCodeToggle from '@common/components/AirportCodeToggle';
import Icon from '@common/components/Icon';
import TimezoneToggle from '@common/components/TimezoneToggle';
import {
  AutoCompleteField,
  DateRangePickerField,
  FlightStatusForm
} from '@common/formElement';
import { FLIGHT_FILTER_FUTURE_DAYS } from '@constant/constant';
import { USER_LEVEL } from '@constant/user_level';
import { Box, Grid, InputAdornment, Paper, Typography } from '@mui/material';
import { userLevelSelector } from '@user/selector/userInfoSelector';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { isObject } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import useSuggestions from 'src/hooks/useSuggestion';

export default function FlightFilterBar({
  isFBOView = false
}: {
  isFBOView?: boolean;
}) {
  const { t } = useTranslation();
  //STATE
  const [searchParams, setSearchParams] = useSearchParams({});
  const userLevel = useSelector(userLevelSelector);
  const isSUView = userLevel === USER_LEVEL.SUPERUSER;

  const [values, setValues] = useState<any>({});
  const mounted = useRef<boolean>(false);
  const [data] = useSuggestions({
    apiUrl: '/flight-assignees',
    searchable: false,
    uniqueKey: 'assigneeList',
    callApiOnMount: true
  });
  const initValues = useMemo(() => {
    const startDate = searchParams.get('from_date');
    const endDate = searchParams.get('to_date');
    const assignee = searchParams.get('assignee');
    return {
      date: {
        startDate: startDate ? dayjs(startDate, 'YYYY-MM-DD') : dayjs(),
        endDate: endDate
          ? dayjs(endDate, 'YYYY-MM-DD')
          : dayjs().add(FLIGHT_FILTER_FUTURE_DAYS, 'day')
      },
      assignee: {
        value: assignee ? parseInt(assignee) : 0,
        label:
          data?.items.find(item => item.value === parseInt(assignee))?.label ??
          'All assignees'
      },
      status: searchParams.get('status')
        ? searchParams
            .get('status')
            .split(',')
            .map(item => parseInt(item))
        : null
    };
  }, [searchParams, data]);

  //HOOKS
  useEffect(() => {
    const {
      date,
      assignee,
      status
    }: {
      date: Record<string, any>;
      assignee: { value: string; label: string } | string;
      status: string[] | null;
    } = values;
    if (!mounted || data.loading) {
      mounted.current = true;
      return;
    }
    const assigneeValue = isObject(assignee) ? assignee.value : assignee;
    setSearchParams(prevParams => {
      const newParams = new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        from_date: date?.startDate?.format('YYYY-MM-DD'),
        to_date: date?.endDate?.format('YYYY-MM-DD'),
        assignee: assigneeValue,
        status: status?.toString()
      });
      if (!status?.length) {
        newParams.delete('status');
      }
      newParams.delete('page');
      if (!date?.startDate) {
        newParams.delete('from_date');
      }
      if (!date?.endDate) {
        newParams.delete('to_date');
      }
      if (!assigneeValue) {
        newParams.delete('assignee');
      }
      return newParams;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Paper sx={{ width: '348px', px: 2, py: 3, height: 'fit-content', mb: 4 }}>
      <Box className="flex-center-rows" sx={{ mb: 2 }}>
        <Icon
          icon="icon-Filter-filled"
          sx={{ color: 'neutral.dark1', mr: 1 }}
        />
        <Typography variant="head4Mons" color="neutral.dark3">
          {t('filters')}
        </Typography>
      </Box>
      <Typography
        variant="subtitle1"
        color="neutral.dark3"
        component="div"
        sx={{ mb: 1.5 }}
      >
        <b>{t('depart_date')}</b>
      </Typography>
      <Formik
        onSubmit={() => {}}
        initialValues={initValues}
        enableReinitialize
        innerRef={formikActions =>
          formikActions ? setValues(formikActions.values) : () => {}
        }
      >
        {({ initialValues }) => (
          <Form>
            <Grid item xs={12}>
              <DateRangePickerField
                name="date"
                config={{
                  fullWidth: true
                }}
              />
            </Grid>
            <Typography
              variant="subtitle1"
              color="neutral.dark3"
              component="div"
              sx={{ mt: 2 }}
            >
              <b>{t('time_zone')} </b>
            </Typography>
            <Typography
              variant="caption"
              color="neutral.dark3"
              component="div"
              sx={{ mb: 1.5 }}
            >
              {t('select_format_date_local_or_utc')}
            </Typography>
            <TimezoneToggle />

            {isFBOView ? null : (
              <>
                <Typography
                  variant="subtitle1"
                  color="neutral.dark3"
                  component="div"
                  sx={{ mt: 2 }}
                >
                  <b>{t('airport_codes')}</b>
                </Typography>
                <Typography
                  variant="caption"
                  color="neutral.dark3"
                  component="div"
                  sx={{ mb: 1.5 }}
                >
                  {t('switch_between_different_airport_code')}
                </Typography>
                <AirportCodeToggle />
                {isSUView ? (
                  <>
                    <Typography
                      variant="subtitle1"
                      color="neutral.dark3"
                      component="div"
                      sx={{ mt: 2, mb: 1.5 }}
                    >
                      <b>{t('assignee')}</b>
                    </Typography>
                    <AutoCompleteField
                      name="assignee"
                      config={{
                        variant: 'outlined',
                        fullWidth: true,
                        searchable: false,
                        search_endpoint: '/flight-assignees',
                        uniqueKey: 'assigneeList',
                        defaultValue: initialValues.assignee,
                        noOptionsText: 'No assignee found',
                        clearIcon: false,
                        shrink: true,
                        callApiOnMount: false,
                        firstItem: [{ value: 0, label: 'All assignees' }],
                        disabled: data?.loading,
                        forcePopupIcon: false,
                        disableClearable: true,
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end" sx={{ pr: 1 }}>
                              <Icon icon="icon-down icon-black" size="sm" />
                            </InputAdornment>
                          )
                        }
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      color="neutral.dark3"
                      component="div"
                      sx={{ mt: 2, mb: 1.5 }}
                    >
                      <b>{t('status')}</b>
                    </Typography>
                    <FlightStatusForm
                      name="status"
                      config={{
                        fullWidth: true
                      }}
                    />
                  </>
                ) : null}
              </>
            )}
          </Form>
        )}
      </Formik>
    </Paper>
  );
}
