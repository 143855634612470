import {
  FLIGHT_FILTER_FUTURE_DAYS,
  FLIGHTS_PER_PAGE
} from '@constant/constant';
import { useGlobal } from '@context/GlobalContext';
import { listEntitySelector } from '@core/selector/entitySelector';
import FlightFilterBar from '@flight/components/flight-list/FlightFilterBar';
import FlightPagingBar from '@flight/components/flight-list/FlightTable/FlightPagingBar';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { FlightListWrapper, Wrapper } from './styled';

const FlightList = () => {
  //STATE
  const { pageParams, jsxBackend } = useGlobal();
  const BlockList = jsxBackend.get('core.block.listItem');
  const { paging, module, resource } = pageParams?.counter ?? {};
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams({
    page: '1',
    from_date: dayjs().format('YYYY-MM-DD'),
    to_date: dayjs().add(FLIGHT_FILTER_FUTURE_DAYS, 'day').format('YYYY-MM-DD')
  });
  const page = parseInt(searchParams.get('page'));
  const startDate = searchParams.get('from_date');
  const endDate = searchParams.get('to_date');
  const assignee = searchParams.get('assignee');
  const status = searchParams.get('status');

  const { meta } = useSelector(state =>
    listEntitySelector(state, paging, module, resource)
  );

  useEffect(() => {
    return () => {
      dispatch({
        type: 'flight/clearPaging'
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //GET DATA
  return (
    <Wrapper sx={{ ml: -8 }}>
      <FlightFilterBar />
      <Box sx={{ flex: 1, mr: 2 }} className="flex-column">
        <Box
          sx={{ pb: 2, pr: 2, alignItems: 'center', pl: 8 }}
          className="flex-space-between-row"
        >
          <FlightPagingBar
            page={page}
            startDate={startDate}
            endDate={endDate}
            data={meta}
            reloadWhen={[startDate, endDate, assignee, status]}
          />
        </Box>
        <Box
          sx={{ overflow: 'auto', flex: 1, pt: 1, pl: 8, minHeight: '648px' }}
        >
          <FlightListWrapper>
            <BlockList
              itemPerPage={FLIGHTS_PER_PAGE}
              itemName="flight.item.flightListItem"
              emptyName="flight.block.flightListEmpty"
              module="flight"
              resource="flight"
              apiUrl="/flights"
              initParams={{ ...Object.fromEntries(searchParams.entries()) }}
              hasPagination={false}
            />
          </FlightListWrapper>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default FlightList;
