import { Grid, Typography } from '@mui/material';
import { TableHeaderWrapper, TableWrapper } from './styled';
import { useTranslation } from 'react-i18next';
const FlightEmbedTableHeader = () => {
  const { t } = useTranslation();
  return (
    <TableHeaderWrapper>
      <TableWrapper>
        <Grid container>
          <Grid sx={{ pl: 2 }} item xs={2}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              {t('flight_no')}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              {t('departing_from')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              {t('legs')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              {t('flight_type')}
            </Typography>
          </Grid>
        </Grid>
      </TableWrapper>
    </TableHeaderWrapper>
  );
};

export default FlightEmbedTableHeader;
