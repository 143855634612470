/*
name: crew.item.crewListItem
notLazy: true
*/

import DirectLink from '@common/components/DirectLink';
import Icon from '@common/components/Icon';
import TruncatedText from '@common/components/TruncatedText';
import { entitySelector } from '@core/selector/entitySelector';
import { tooltipPassportStatusDataForCrew } from '@helper/stringFunction';
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  AvatarWrapper,
  CrewItemWrapper,
  MenuWrapper,
  TableWrapper
} from './styled';

export default function CrewTableItem({ id }: { id: number }) {
  const data = useSelector(state => entitySelector(state, 'crew', 'crew', id));
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const anchorPopover = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  if (isEmpty(data)) return null;

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { firstName, lastName, deleting, email } = data;

  const placeholder = (
    <Typography variant="body2" color="neutral.main">
      <i>{t('waiting')}</i>
    </Typography>
  );

  const crewName = `${firstName ?? ''} ${lastName ?? ''}`.trim();

  const onDeleteCrew = () => {
    handleClose();
    dispatch({
      type: '@crew/deleteCrew',
      payload: { id: data.id, crewName: crewName || email }
    });
  };

  return (
    <CrewItemWrapper deleting={deleting}>
      <AvatarWrapper>
        <DirectLink to={`/crew/${id}`}>
          <Avatar
            className="fs-exclude"
            variant="square"
            sx={{
              width: 64,
              height: 64,
              bgcolor: 'neutral.light2'
            }}
            src={data?.onfido_image}
          />
        </DirectLink>
      </AvatarWrapper>
      <TableWrapper>
        <DirectLink to={`/crew/${id}`}>
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={1} sx={{ pl: 2.5 }}>
              <Typography color="neutral.dark1" variant="body2" noWrap>
                {data.id}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ width: '100%', pr: 7 }}>
                <TruncatedText color="neutral.black" variant="subtitle3" noWrap>
                  {crewName || placeholder}
                </TruncatedText>
                <TruncatedText color="neutral.black" variant="caption" noWrap>
                  {email}
                </TruncatedText>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <TruncatedText color="neutral.dark3" variant="body2" noWrap>
                {data?.date_of_birth ?? placeholder}
              </TruncatedText>
            </Grid>
            <Grid item xs={4}>
              <Typography color="neutral.dark3" variant="body2" noWrap>
                {data.crew_type?.crew_type_name ?? placeholder}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Tooltip
                title={t(tooltipPassportStatusDataForCrew(data.status).text)}
                arrow
              >
                <Icon
                  icon={tooltipPassportStatusDataForCrew(data.status).icon}
                  size="lg"
                  color={tooltipPassportStatusDataForCrew(data.status).color}
                  haveBackground
                />
              </Tooltip>
            </Grid>
          </Grid>
        </DirectLink>
      </TableWrapper>
      <MenuWrapper ref={anchorPopover} onClick={handleClick}>
        <IconButton
          size="medium"
          sx={{
            ':hover': {
              '.icon': {
                color: 'primary.main',
                transition: 'all 0.2s ease-in-out'
              }
            }
          }}
        >
          <Icon
            icon="icon-dot-menu"
            sx={{ color: 'neutral.dark1' }}
            size="sm"
          />
        </IconButton>
      </MenuWrapper>
      <Menu
        open={Boolean(open)}
        anchorEl={anchorPopover.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ mt: -1.5 }}
      >
        <DirectLink to={`/crew/${id}`}>
          <MenuItem disableRipple>
            <Typography color="neutral.dark3" variant="body2">
              {t('view_details')}
            </Typography>
          </MenuItem>
        </DirectLink>

        <MenuItem onClick={onDeleteCrew} disableRipple>
          <Typography color="danger.main" variant="body2">
            {t('remove')}
          </Typography>
        </MenuItem>
      </Menu>
    </CrewItemWrapper>
  );
}
