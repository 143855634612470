import Icon from '@common/components/Icon';
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  TextField
} from '@mui/material';
import classNames from 'classnames';
import { useField } from 'formik';
import { camelCase } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSuggestions from 'src/hooks/useSuggestion';

const AutoCompleteField = ({ config, name }) => {
  const {
    variant = 'filled',
    label,
    size = 'small',
    fieldValue = 'value',
    required,
    defaultValue,
    fullWidth = true,
    search_endpoint,
    searchable = true,
    noOptionsText = 'Not found',
    uniqueKey,
    firstItem = [],
    shrink,
    disabled = false,
    callApiOnMount = true,
    disableClearable = false,
    forcePopupIcon = null,
    InputProps
  } = config;
  const { t } = useTranslation();
  const [field, meta, { setValue, setTouched }] = useField(
    name || 'autoComplete'
  );
  const [optionValue, setOptionValue] = React.useState(field.value);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setValue(field.value ? field.value[fieldValue] : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    defaultValue && setOptionValue(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const [data, handleChanged] = useSuggestions({
    apiUrl: search_endpoint,
    searchable,
    uniqueKey: uniqueKey,
    callApiOnMount
  });

  const onInputChange = value => {
    if (value.length === 1 || !searchable) return;
    handleChanged(value?.split(',')[0]?.trim());
  };

  const onBlur = (e: any) => {
    field.onBlur(e);
    setTouched(true);
  };

  return (
    <FormControl
      variant={variant as any}
      fullWidth={fullWidth}
      required={required}
      size={size}
    >
      <Autocomplete
        autoHighlight
        openOnFocus
        clearIcon={false}
        onBlur={field.onBlur}
        onInputChange={(evt, values) => {
          onInputChange(values);
        }}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        onChange={(evt, newValue) => {
          setValue(newValue?.[fieldValue] || null);
          setOptionValue(newValue);
        }}
        disabled={disabled}
        value={optionValue}
        getOptionLabel={option => option.label}
        options={firstItem.concat(data.items)}
        loading={data.loading}
        forcePopupIcon={forcePopupIcon}
        disableClearable={disableClearable}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        noOptionsText={noOptionsText}
        ListboxProps={{ style: { maxHeight: 320 } }}
        loadingText={
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        }
        renderOption={(props: object, option: any, state: object) => {
          return (
            <MenuItem
              value={option.value}
              {...props}
              key={props['data-option-index']}
            >
              {option.label}
            </MenuItem>
          );
        }}
        renderInput={params => {
          return (
            <TextField
              onFocus={() => {
                handleChanged(optionValue?.label?.split(',')[0]?.trim() ?? '');
              }}
              onBlur={onBlur}
              label={label}
              {...params}
              InputLabelProps={{ shrink: shrink }}
              required={required}
              data-testid={camelCase(`field ${name}`)}
              variant={variant}
              size={size}
              fullWidth
              InputProps={{
                ...params.InputProps,
                ...InputProps,
                ...(variant === 'outlined' && {
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ pr: 1, cursor: 'pointer' }}
                      onClick={() => setOpen(prev => !prev)}
                    >
                      <Icon
                        icon="icon-down icon-black"
                        size="sm"
                        className={classNames({ 'icon-flip-y': open })}
                      />
                    </InputAdornment>
                  )
                })
              }}
              disabled={disabled}
              error={!!meta.error && meta.touched}
            />
          );
        }}
      />
      {meta.error && meta.touched ? (
        <div className="errorMessage">{t(meta.error)}</div>
      ) : null}
    </FormControl>
  );
};

export default AutoCompleteField;
