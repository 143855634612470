/*
name: client.item.clientListItemSkeleton
notLazy: true
*/

import Icon from '@common/components/Icon';
import { Grid, IconButton, Skeleton, Typography } from '@mui/material';
import {
  AvatarWrapper,
  ClientItemWrapper,
  MenuWrapper,
  TableWrapper
} from './styled';
const ClientTableItemSkeleton = () => {
  return (
    <ClientItemWrapper>
      <AvatarWrapper>
        <Skeleton
          variant="rectangular"
          width={64}
          height={64}
          className="avatar"
        />
      </AvatarWrapper>
      <TableWrapper>
        <Grid container>
          <Grid sx={{ pl: 2 }} item xs={(120 / 1104) * 12}>
            <Skeleton variant="text" width={40} />
          </Grid>
          <Grid item xs={(232 / 1104) * 12}>
            <Skeleton variant="text" width={120} />
          </Grid>
          <Grid item xs={(160 / 1104) * 12}>
            <Skeleton variant="text" width={80} />
          </Grid>
          <Grid item xs={(248 / 1104) * 12}>
            <Skeleton variant="text" width={80} />
          </Grid>
          <Grid item xs={(248 / 1104) * 12}>
            <Skeleton variant="text" width={80} />
          </Grid>
          <Grid item xs={(96 / 1104) * 12}>
            <Skeleton variant="circular" width={40} height={40} />
          </Grid>
          <Grid item xs={'auto'}>
            <Typography color="neutral.dark1" variant="subtitle2"></Typography>
          </Grid>
        </Grid>
      </TableWrapper>
      <MenuWrapper>
        <IconButton
          size="medium"
          sx={{
            ':hover': {
              '.icon': {
                color: 'primary.main',
                transition: 'all 0.2s ease-in-out'
              }
            }
          }}
        >
          <Icon
            icon="icon-dot-menu"
            sx={{ color: 'neutral.dark1' }}
            size="sm"
          />
        </IconButton>
      </MenuWrapper>
    </ClientItemWrapper>
  );
};

export default ClientTableItemSkeleton;
