import Icon from '@common/components/Icon';
import { SectionWrapper } from '@flight/pages/flight-detail/styled';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import { EmptyBox } from '../styled';
import { CrewWrapper } from './styled';
import { useParams } from 'react-router';
import TruncatedText from '@common/components/TruncatedText';
import { useFlightDetail } from '@flight/context/flightDetailContext';
import { getName } from '@helper/stringFunction';
import { useTranslation } from 'react-i18next';

const CrewItem = ({ data }) => {
  const { user, crewTypeName } = data;
  return (
    <CrewWrapper>
      <Avatar
        className="fs-exclude"
        variant="circular"
        sx={{
          width: 48,
          height: 48
        }}
        src={data?.user?.onfido_image}
      />
      <Box
        className="flex-column"
        sx={{ alignItems: 'flex-start', flex: 1, gap: 0.25, minWidth: 0 }}
      >
        <TruncatedText variant="subtitle2" color="neutral.dark3">
          {crewTypeName}
        </TruncatedText>
        <TruncatedText variant="subtitle1" noWrap color="primary.dark3">
          <b>{getName(user)}</b>
        </TruncatedText>
      </Box>
    </CrewWrapper>
  );
};

const CrewSection = () => {
  const { t } = useTranslation();
  const { legData } = useFlightDetail();
  const { id } = useParams();
  const { crewList } = legData;
  const onEditCrew = () => {
    window.location.href = `${process.env.REACT_APP_PHP_BASE_URL}/flights.php?input=${id}`;
  };
  return (
    <SectionWrapper>
      <Box className="flex-center-rows" sx={{ gap: 1, mb: 4 }}>
        <Icon icon="icon-crew-2" size="lg" sx={{ color: 'primary.dark1' }} />
        <Typography variant="head3Mons" color="primary.dark2">
          {t('crew_information')}
        </Typography>
        <Box className="flex-column" sx={{ flex: 1, alignItems: 'flex-end' }}>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            startIcon={
              <Icon
                icon="icon-Pencil-alt-filled"
                sx={{ color: 'primary.dark2' }}
              />
            }
            onClick={onEditCrew}
          >
            {t('edit_crew')}
          </Button>
        </Box>
      </Box>
      {crewList?.length ? (
        <Grid container columnSpacing={4} rowSpacing={4}>
          {crewList.map((item, index) => {
            return (
              <Grid item xs={3}>
                <CrewItem data={item} />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <EmptyBox>
          <Typography variant="body2" color="neutral.dark1">
            {t('no_crew_assigned_this_leg')}
          </Typography>
        </EmptyBox>
      )}
    </SectionWrapper>
  );
};

export default CrewSection;
