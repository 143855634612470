import Icon from '@common/components/Icon';
import { TextFieldForm } from '@common/formElement';
import { Box, Grid } from '@mui/material';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  CrewPreview,
  PilotImage,
  PilotImageTitle,
  PilotImageWrapper
} from './styled';

const PilotLicense = ({ initialValues }) => {
  const { t } = useTranslation();
  const pilotImages = [
    {
      title: t('front_side'),
      image: get(initialValues, 'pilot_license.front_image_url'),
      alt: get(initialValues, 'pilot_license.front_image_name')
    },
    {
      title: t('back_side'),
      image: get(initialValues, 'pilot_license.back_image_url'),
      alt: get(initialValues, 'pilot_license.back_image_name')
    }
  ];

  const onPreview = (initialIndex: number): void => {
    DialogJSX.open({
      component: 'crew.dialog.previewCrewMedical',
      props: {
        title: 'view_pilot_doc',
        cancelButtonLabel: 'close',
        images: pilotImages.map(pilotImage => ({
          url: pilotImage.image,
          alt: pilotImage.alt
        })),
        initialIndex
      },
      inMobile: 'full'
    });
  };

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
          <Grid item xs={12} sm={6}>
            <TextFieldForm
              name="pilot_license.pilot_licence_number"
              config={{
                label: t('licence_issuing_author'),
                readOnly: true
              }}
              initialValues={initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldForm
              name="pilot_license.licence_issuing_authority"
              config={{
                label: t('pilot_licence_number'),
                readOnly: true
              }}
              initialValues={initialValues}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
          <Grid item xs={12} sm={6}>
            <TextFieldForm
              name="pilot_license.date_of_issue"
              config={{
                label: t('date_of_issue'),
                readOnly: true
              }}
              initialValues={initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldForm
              name="pilot_license.expiration_date"
              config={{
                label: t('expiration_date'),
                readOnly: true
              }}
              initialValues={initialValues}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 1, display: 'flex', gap: '32px' }}>
        {pilotImages.map((pilotImage, index) => (
          <PilotImageWrapper key={pilotImage.title}>
            <PilotImageTitle>{pilotImage.title}</PilotImageTitle>
            {pilotImage.image ? (
              <>
                <PilotImage src={pilotImage.image} alt={pilotImage.alt} />
                <CrewPreview
                  onClick={() => onPreview(index)}
                  className="crew-preview"
                >
                  <Icon icon="icon-Expand" color="white" />
                </CrewPreview>
              </>
            ) : null}
          </PilotImageWrapper>
        ))}
      </Box>
    </>
  );
};

export default PilotLicense;
