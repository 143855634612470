import TruncatedText from '@common/components/TruncatedText';
import { Avatar, Box, Skeleton } from '@mui/material';
import { get, isEmpty, trim } from 'lodash';
import { useMemo } from 'react';
import { CrewBanner, CrewBannerDivider, CrewBannerRight } from './styled';
import Icon from '@common/components/Icon';
import { useTranslation } from 'react-i18next';

export default function Banner({ data }) {
  const { t } = useTranslation();
  const crewStatus = useMemo(() => {
    switch (data.status) {
      case 1:
        return {
          title: 'Awaiting crew acceptance',
          color: 'warning.dark1',
          name: 'icon-passport-pending-bold',
          background: 'warning.light3'
        };
      case 3:
        return {
          title: 'Identify verified',
          color: 'positive.dark1',
          background: 'positive.light3',
          name: 'icon-passport-pending-bold'
        };
      case 4:
        return {
          title: 'Identify rejected',
          color: 'danger.main',
          background: 'danger.light3',
          name: 'icon-passport-fail-bold'
        };
      default:
        return {
          title: 'Identify verifying',
          color: 'primary.main',
          background: 'primary.light3',
          name: 'icon-passport-bold'
        };
    }
  }, [data]);

  const placeholder = t('waiting');

  const fullName =
    trim(`${get(data, 'firstName') || ''} ${get(data, 'lastName') || ''}`) ||
    placeholder;

  return (
    <CrewBanner className="flex-center-rows" sx={{ gap: 4 }}>
      <Avatar
        sx={theme => ({
          width: 80,
          height: 80,
          border: `4px solid ${theme.palette.positive.dark1}`,
          background: '#B9CFEF'
        })}
        src={data.onfido_image}
      />
      <Box
        className="flex-center-rows"
        sx={{ flexGrow: 1, overflow: 'hidden' }}
      >
        <Box
          className="flex-center-rows"
          sx={{ gap: 2, flexGrow: 0, overflow: 'hidden', maxWidth: '300px' }}
        >
          <Icon icon="icon-profile" sx={{ color: 'primary.dark2' }} />
          {!isEmpty(data) ? (
            <TruncatedText variant="subtitle1" color="primary.dark2">
              <b>{fullName}</b>
            </TruncatedText>
          ) : (
            <Skeleton width="100px" />
          )}
        </Box>
        <CrewBannerDivider />
        <Box
          className="flex-center-rows"
          sx={{ gap: 2, flexGrow: 1, overflow: 'hidden' }}
        >
          <Icon icon="icon-mail" sx={{ color: 'primary.dark2' }} />
          {!isEmpty(data) ? (
            <TruncatedText variant="subtitle1" color="primary.dark2">
              {data.email || placeholder}
            </TruncatedText>
          ) : (
            <Skeleton width="100px" />
          )}
        </Box>
      </Box>
      {!isEmpty(data) ? (
        <CrewBannerRight
          sx={{
            backgroundColor: crewStatus.background,
            borderColor: crewStatus.color,
            color: crewStatus.color
          }}
        >
          <Icon
            sx={{ color: crewStatus.color }}
            icon={crewStatus.name}
            size="lg"
          />
          {crewStatus.title}
        </CrewBannerRight>
      ) : (
        <Skeleton width="150px" height="50px" variant="rounded" />
      )}
    </CrewBanner>
  );
}
