import { Box, Typography } from '@mui/material';
import { Banner, Title, Wrapper } from './styled';
import { Trans, useTranslation } from 'react-i18next';

export default function ExpiredLinkPage(props: any) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Box
        sx={{
          maxWidth: { md: '600px', xs: '100%' },
          height: { xs: '100%', md: 'auto' }
        }}
        className="flex-center-columns"
      >
        <Banner sx={{ width: '100%' }} className="flex-center-columns">
          <img src="/images/logo_line.svg" alt="" style={{ height: 40 }} />
        </Banner>
        <Box
          className="flex-center-columns"
          sx={{
            pl: { xs: 3, sm: 5 },
            pr: { xs: 3, sm: 5 },
            pt: { xs: 8, sm: 5 },
            pb: { xs: 8, sm: 5 },
            backgroundColor: 'neutral.light3',
            flex: { xs: 1, md: 'auto' }
          }}
        >
          <img src="/images/expired.svg" alt="" />
          <Title sx={{ mt: 1, mb: 3 }}>{t('expired_link')}</Title>
          <ul>
            <li>
              <Typography variant="body2" color="neutral.dark3">
                {t('link_you_clicked_expired_text')}
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="neutral.dark3">
                <Trans
                  i18nKey={'please_contact_us_to_register_text'}
                  components={{
                    a: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        className="highlight"
                        href="mailto:enquiries@maketechfly.com"
                      />
                    )
                  }}
                />
              </Typography>
            </li>
          </ul>
        </Box>
      </Box>
    </Wrapper>
  );
}
