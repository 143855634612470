import { CrewBanner, CrewContent } from '@crew/components/crew-detail';
import CrewDetailHeader from '@crew/components/crew-detail/Header';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import useFetchDetail from 'src/hooks/useFetchDetail';
import { Body, CrewHeader } from './styled';

const CrewDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [data, loading] = useFetchDetail(
    'crew',
    'crew',
    '/verify/crews/:id',
    parseInt(id)
  );
  return (
    <>
      <CrewDetailHeader />
      <Body sx={{ px: 8 }}>
        <CrewHeader>{t('crew_details')}</CrewHeader>
        <CrewBanner data={data} />
        <CrewContent data={data} loading={loading} />
      </Body>
    </>
  );
};

export default CrewDetail;
