import { TableCell, styled } from '@mui/material';
export const ClientItemWrapper = styled('div', {
  name: 'ClientItemWrapper',
  shouldForwardProp: prop => prop !== 'deleting'
})<{ deleting?: boolean }>(({ theme, deleting }) => ({
  borderRadius: '4px',
  display: 'flex',
  height: '64px',
  backgroundColor: theme.palette.neutral.white,
  border: '1px solid',
  borderColor: theme.palette.neutral.light2,
  boxShadow: '0px 2px 4px 0px rgb(88,92,97, 0.24)',
  overflow: 'hidden',
  ...(deleting === true && {
    opacity: 0.3,
    pointerEvents: 'none',
    cursor: 'default'
  })
}));

export const AvatarWrapper = styled('div', { name: 'AvatarWrapper' })(
  ({ theme }) => ({
    width: '64px',
    backgroundColor: theme.palette.neutral.light3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative'
  })
);

export const GuestBadge = styled('div', { name: 'GuestBadge' })(
  ({ theme }) => ({
    position: 'absolute',
    textTransform: 'uppercase',
    fontFamily: 'Open Sans',
    backgroundColor: theme.palette.primary.light2,
    padding: '4px 4px',
    bottom: '4px',
    fontSize: '13px',
    fontWeight: '600',
    lineHeight: '9px',
    letterSpacing: '0.5px',
    textAlign: 'center',
    color: theme.palette.primary.dark2,
    borderRadius: '16px'
  })
);
export const MenuWrapper = styled('div', { name: 'MenuWrapper' })(
  ({ theme }) => ({
    width: '64px',
    paddingRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right'
  })
);

export const TableWrapper = styled('div', {
  name: 'TableItemWrapper'
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  height: '100%',
  marginRight: '-64px',
  paddingRight: '64px',
  '.MuiGrid-item': {
    display: 'flex',
    alignItems: 'center'
  }
}));

export const StyledTableCell = styled(TableCell, {
  name: 'StyledTableCell'
})(({ theme }) => ({
  paddingTop: 8,
  paddingBottom: 8,
  maxWidth: 300,
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));
