import { Grid, Typography } from '@mui/material';
import {
  AvatarWrapper,
  MenuWrapper,
  TableHeaderWrapper,
  TableWrapper
} from './styled';
import { useTranslation } from 'react-i18next';
const PassengerFlightHeader = () => {
  const { t } = useTranslation();
  return (
    <TableHeaderWrapper>
      <AvatarWrapper></AvatarWrapper>
      <TableWrapper>
        <Grid container>
          <Grid item xs sx={{ pl: 1 }}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              {t('name')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              {t('passport_status')}
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              {t('luggage')}
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              {t('catering')}
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              {t('food_allergy')}
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              {t('UKBF_status')}
            </Typography>
          </Grid>
        </Grid>
      </TableWrapper>
      <MenuWrapper sx={{ mr: 3 }}>
        <Typography color="neutral.dark1" variant="subtitle2" noWrap>
          {t('action')}
        </Typography>
      </MenuWrapper>
    </TableHeaderWrapper>
  );
};

export default PassengerFlightHeader;
