import { Grid, Typography } from '@mui/material';
import { MenuWrapper, TableHeaderWrapper, TableWrapper } from './styled';
import { useTranslation } from 'react-i18next';

const CrewTableHeader = () => {
  const { t } = useTranslation();

  return (
    <TableHeaderWrapper>
      <TableWrapper>
        <Grid container>
          <Grid item sx={{ pl: 2.5 }} xs={1}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              {t('ID')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              {t('name')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              {t('date_of_birth')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              {t('crew_type')}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              {t('crew_status')}
            </Typography>
          </Grid>
        </Grid>
      </TableWrapper>
      <MenuWrapper></MenuWrapper>
    </TableHeaderWrapper>
  );
};

export default CrewTableHeader;
