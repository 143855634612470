/*
name: crew.item.crewListItemSkeleton
notLazy: true
*/

import Icon from '@common/components/Icon';
import { Grid, IconButton, Skeleton, Typography } from '@mui/material';
import {
  AvatarWrapper,
  CrewItemWrapper,
  MenuWrapper,
  TableWrapper
} from './styled';
const CrewTableItemSkeleton = () => {
  const tableCells = [
    { sx: { pl: 2.5 }, xs: 1 },
    { xs: 4 },
    { xs: 2 },
    { xs: 4 }
  ];

  return (
    <CrewItemWrapper>
      <AvatarWrapper>
        <Skeleton
          variant="rectangular"
          width={64}
          height={64}
          className="avatar"
        />
      </AvatarWrapper>
      <TableWrapper>
        <Grid container>
          {tableCells.map((cell, index) => (
            <Grid sx={cell.sx} item xs={cell.xs} key={index}>
              <Skeleton variant="text" width={50} />
            </Grid>
          ))}
          <Grid item xs={1}>
            <Skeleton variant="circular" width={40} height={40} />
          </Grid>
          <Grid item xs={'auto'}>
            <Typography color="neutral.dark1" variant="subtitle2" />
          </Grid>
        </Grid>
      </TableWrapper>
      <MenuWrapper>
        <IconButton
          size="medium"
          sx={{
            ':hover': {
              '.icon': {
                color: 'primary.main',
                transition: 'all 0.2s ease-in-out'
              }
            }
          }}
        >
          <Icon
            icon="icon-dot-menu"
            sx={{ color: 'neutral.dark1' }}
            size="sm"
          />
        </IconButton>
      </MenuWrapper>
    </CrewItemWrapper>
  );
};

export default CrewTableItemSkeleton;
