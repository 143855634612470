import { styled } from '@mui/material';

export const Wrapper = styled('div', {
  name: 'Wrapper'
})(({ theme }) => ({
  height: '100%',
  flex: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  display: 'flex',
  paddingRight: theme.spacing(8),
  flexDirection: 'row-reverse'
}));

export const FlightListWrapper = styled('div', { name: 'FlightListWrapper' })(
  ({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  })
);
