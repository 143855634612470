import { createSlice } from '@reduxjs/toolkit';
import { createResourceReducers } from '@services/createResourceReducer';

export const clientsReducer = createSlice({
  name: 'client',
  initialState: {
    client: {
      data: {},
      paging: {}
    }
  },
  reducers: {
    ...createResourceReducers('client')
  }
});
