import Icon from '@common/components/Icon';
import TruncatedText from '@common/components/TruncatedText';
import { useFlightDetail } from '@flight/context/flightDetailContext';
import { SectionWrapper } from '@flight/pages/flight-detail/styled';
import { Box, IconButton, Typography } from '@mui/material';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import useAirportCode from 'src/hooks/useAirportCode';
import { Column, ContentWrapper, Divider, IconWrapper } from './styled';
import { useTranslation } from 'react-i18next';

const ParkingSection = () => {
  const { t } = useTranslation();
  const { legData } = useFlightDetail();
  const airportCodeConfig = useAirportCode();
  const { parkingRequest, airSideRequest, arrive_airport } = legData;

  const onComingSoon = () => {
    DialogJSX.comingsoon();
  };

  return (
    <SectionWrapper>
      <Box className="flex-center-rows" sx={{ gap: 1, mb: 4 }}>
        <Icon icon="icon-parking-2" size="lg" sx={{ color: 'primary.dark1' }} />
        <Typography variant="head3Mons" color="primary.dark2">
          {t('request_parking')}
        </Typography>
      </Box>
      {parkingRequest && airSideRequest ? (
        <ContentWrapper>
          <Column>
            <IconWrapper
              sx={{
                backgroundColor: 'neutral.white'
              }}
            >
              <Icon
                icon="icon-parking-2"
                size="lg"
                sx={{ color: 'primary.light1' }}
              />
            </IconWrapper>
            <Box
              className="flex-column"
              sx={{ alignItems: 'flex-start', flex: 4 }}
            >
              <TruncatedText variant="subtitle2" color="neutral.dark3">
                {t('parking_for_air_name_code', {
                  name: arrive_airport.name,
                  code: arrive_airport[airportCodeConfig]
                })}
              </TruncatedText>
              <TruncatedText
                variant="subtitle1"
                color={parkingRequest.length ? 'primary.dark1' : 'neutral.main'}
              >
                <b>
                  {parkingRequest.length
                    ? t('parking_request_for_n_vehicles', {
                        vehicleAmount: parkingRequest[0]?.vehicleAmount
                      })
                    : t('you_have_not_requested')}
                </b>
              </TruncatedText>
            </Box>
            <Box className="flex-column" sx={{ alignItems: 'flex-end' }}>
              <IconButton
                color={parkingRequest.length ? 'error' : 'inherit'}
                onClick={onComingSoon}
              >
                <Icon
                  icon={
                    parkingRequest.length
                      ? 'icon-trash-fill'
                      : 'icon-Plus-filled'
                  }
                  size="sm"
                ></Icon>
              </IconButton>
            </Box>
          </Column>
          <Divider />
          <Column>
            <IconWrapper
              sx={{
                backgroundColor: 'neutral.white'
              }}
            >
              <Icon
                icon="icon-airside"
                size="lg"
                sx={{ color: 'primary.light1' }}
              />
            </IconWrapper>
            <Box
              className="flex-column"
              sx={{ alignItems: 'flex-start', flex: 4 }}
            >
              <TruncatedText variant="subtitle2" color="neutral.dark3">
                {t('airside_for_air_name_code', {
                  name: arrive_airport.name,
                  code: arrive_airport[airportCodeConfig]
                })}
              </TruncatedText>
              <TruncatedText
                variant="subtitle1"
                color={airSideRequest.length ? 'primary.dark1' : 'neutral.main'}
              >
                <b>
                  {airSideRequest.length
                    ? t('airside_request_for_vehicle', {
                        vehicle: airSideRequest[0].vehicleReg
                      })
                    : t('you_have_not_requested')}
                </b>
              </TruncatedText>
            </Box>
            <Box className="flex-column" sx={{ alignItems: 'flex-end' }}>
              <IconButton
                color={airSideRequest.length ? 'error' : 'inherit'}
                onClick={onComingSoon}
              >
                <Icon
                  icon={
                    airSideRequest.length
                      ? 'icon-trash-fill'
                      : 'icon-Plus-filled'
                  }
                  size="sm"
                ></Icon>
              </IconButton>
            </Box>
          </Column>
        </ContentWrapper>
      ) : null}
    </SectionWrapper>
  );
};

export default ParkingSection;
