import { lazy } from 'react';

// Importing non-lazy components
import listItem from './../../common/block/BlockList';
import embedItemClientDetailSkeleton from './../../modules/flight/components/flight-embed/FlightEmbedBlock/Item/LoadingSkeleton';
import flightListItemSkeleton from './../../modules/flight/components/flight-list/FlightTable/Item/LoadingSkeleton';
import flightListItem from './../../modules/flight/components/flight-list/FlightTable/Item/index';
import clientListItemSkeleton from './../../modules/client/components/client-list/Item/LoadingSkeleton';
import crewListItemSkeleton from './../../modules/crew/components/crew-list/Item/LoadingSkeleton';
import crewListItem from './../../modules/crew/components/crew-list/Item/index';

// Lazy imports for components that should be lazily loaded
const commingsoon = lazy(() => import('./../../common/dialog/Comingsoon/index'));
const confirm = lazy(() => import('./../../common/dialog/ConfirmDialog/index'));
const addCatering = lazy(() => import('./../../common/dialog/AddCatering/index'));
const addPax = lazy(() => import('./../../common/dialog/AddPassenger/index'));
const editLuggage = lazy(() => import('./../../common/dialog/EditLuggage/index'));
const passengerCatering = lazy(() => import('./../../common/dialog/PassengerCatering/index'));
const passengerLuggage = lazy(() => import('./../../common/dialog/PassengerLuggage/index'));
const embedFlightListEmpty = lazy(() => import('./../../modules/flight/components/flight-embed/FlightEmbedBlock/EmptyFlight'));
const flightListEmpty = lazy(() => import('./../../modules/flight/components/flight-list/FlightTable/EmptyFlightTable'));
const embedItemClientDetail = lazy(() => import('./../../modules/flight/components/flight-embed/FlightEmbedBlock/Item/index'));
const inviteClient = lazy(() => import('./../../common/dialog/AddClientDialog/index'));
const addRoute = lazy(() => import('./../../common/dialog/AddRouteDialog/index'));
const editClient = lazy(() => import('./../../common/dialog/EditClientDialog/index'));
const clientListEmpty = lazy(() => import('./../../modules/client/components/client-list/EmptyClientTable'));
const clientListItem = lazy(() => import('./../../modules/client/components/client-list/Item/index'));
const clientInFlight = lazy(() => import('./../../common/dialog/ClientInFlightDialog/index'));
const inviteCrew = lazy(() => import('./../../common/dialog/InviteCrewDialog/index'));
const previewCrewMedical = lazy(() => import('./../../common/dialog/PreviewCrewMedical/index'));
const crewListEmpty = lazy(() => import('./../../modules/crew/components/crew-list/EmptyCrewTable'));
const registerSuccess = lazy(() => import('./../../common/dialog/RegisterSuccessDialog/index'));

const componentMap = {
  core: {
    block: {
      'core.block.listItem': listItem,
    },
    dialog: {
      'core.dialog.commingsoon': commingsoon,
      'core.dialog.confirm': confirm,
    },
  },
  flight: {
    dialog: {
      'flight.dialog.addCatering': addCatering,
      'flight.dialog.addPax': addPax,
      'flight.dialog.editLuggage': editLuggage,
      'flight.dialog.passengerCatering': passengerCatering,
      'flight.dialog.passengerLuggage': passengerLuggage,
    },
    block: {
      'flight.block.embedFlightListEmpty': embedFlightListEmpty,
      'flight.block.flightListEmpty': flightListEmpty,
    },
    item: {
      'flight.item.embedItemClientDetailSkeleton': embedItemClientDetailSkeleton,
      'flight.item.embedItemClientDetail': embedItemClientDetail,
      'flight.item.flightListItemSkeleton': flightListItemSkeleton,
      'flight.item.flightListItem': flightListItem,
    },
  },
  client: {
    dialog: {
      'client.dialog.inviteClient': inviteClient,
      'client.dialog.addRoute': addRoute,
      'client.dialog.editClient': editClient,
    },
    block: {
      'client.block.clientListEmpty': clientListEmpty,
    },
    item: {
      'client.item.clientListItemSkeleton': clientListItemSkeleton,
      'client.item.clientListItem': clientListItem,
    },
  },
  error: {
    dialog: {
      'error.dialog.clientInFlight': clientInFlight,
    },
  },
  crew: {
    dialog: {
      'crew.dialog.inviteCrew': inviteCrew,
      'crew.dialog.previewCrewMedical': previewCrewMedical,
    },
    block: {
      'crew.block.crewListEmpty': crewListEmpty,
    },
    item: {
      'crew.item.crewListItemSkeleton': crewListItemSkeleton,
      'crew.item.crewListItem': crewListItem,
    },
  },
  register: {
    dialog: {
      'register.dialog.registerSuccess': registerSuccess,
    },
  },
};

export default componentMap;
