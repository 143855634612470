import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createResourceReducers } from '@services/createResourceReducer';
import { produce } from 'immer';
import {
  get,
  isArray,
  isObject,
  merge,
  remove,
  set,
  unset,
  without
} from 'lodash';

export const flightsReducer = createSlice({
  name: 'flight',
  initialState: {
    flight: { data: {}, paging: {} },
    passenger: { data: {}, paging: {} }
  },
  reducers: {
    ...createResourceReducers('flight'),
    addLuggage: (state, action: PayloadAction<Record<string, any>>) => {
      const { paxId, data } = action.payload;
      const newState = produce(state.passenger.data, draft => {
        set(
          draft,
          [paxId, 'customer', 'luggage'],
          [...get(draft, [paxId, 'customer', 'luggage']), data]
        );
      });
      state.passenger.data = newState;
    },
    deleteLuggage: (state, action: PayloadAction<Record<string, any>>) => {
      const { luggageId } = action.payload;
      function removeObjectRecursively(obj, predicate) {
        Object.keys(obj).forEach(key => {
          const value = obj[key];
          if (isArray(value)) {
            remove(value, predicate);
          } else if (isObject(value)) {
            removeObjectRecursively(value, predicate);
          }
        });
      }
      const newState = produce(state.passenger.data, draft => {
        removeObjectRecursively(draft, item => item.luggage_id === luggageId);
      });
      state.passenger.data = newState;
    },
    updatePax: (state, action: PayloadAction<Record<string, any>>) => {
      const { data } = action.payload;
      const newState = produce(state.passenger.data, draft => {
        draft[data.id] = data;
      });
      state.passenger.data = newState;
    },
    addPax: (state, action: PayloadAction<Record<string, any>>) => {
      const { data } = action.payload;
      const newState = produce(state.passenger, draft => {
        draft.data[data.id] = data;
        const flightPaging = `/flight-passenger?flight_line_id=${data.flightLineId}`;
        draft.paging[flightPaging].data = [
          ...draft.paging[flightPaging].data,
          data.id
        ];
      });
      state.passenger = newState;
    },
    deletePax: (state, action: PayloadAction<Record<string, any>>) => {
      const { paxId, flightLines_Id } = action.payload;
      const newState = produce(state.passenger, draft => {
        unset(draft.data, paxId);
        const flightPaging = `/flight-passenger?flight_line_id=${flightLines_Id}`;
        draft.paging[flightPaging].data = without(
          draft.paging[flightPaging].data,
          paxId
        );
      });
      state.passenger = newState;
    },
    updateEntity: (state, action: PayloadAction<Record<string, any>>) => {
      const { data, path, deepMerge } = action.payload;

      return produce(state, draft => {
        const exists = get(draft, path); // Get the current value at the specified path
        if (!exists) {
          console.warn(`Path does not exist: ${path}`);
          return; // Early return if the path does not exist
        }

        // Merge the data depending on whether deep merging is required
        if (deepMerge) {
          merge(exists, data); // Deep merge into the existing value
        } else {
          Object.assign(exists, data); // Shallow merge into the existing value
        }

        // No need to set the value back since 'exists' is already a reference to the object in 'draft'
      });
    },
    refreshPaging: (state, action: PayloadAction<Record<string, any>>) => {
      const resources = action.payload?.resources || ['flight'];
      resources.forEach(item => {
        const pagingData = state[item].paging;
        Object.keys(pagingData).forEach(key => {
          pagingData[key] = {
            refreshing: true,
            data: [],
            next_cursor: null
          };
        });
      });
    },
    clearPaging: (state, action: PayloadAction<Record<string, any>>) => {
      const resources = action.payload?.resources || ['flight'];
      resources.forEach(item => {
        const pagingData = state[item].paging;
        Object.keys(pagingData).forEach(key => {
          pagingData[key] = undefined;
        });
      });
    }
  }
});
