import { ClientTableHeader } from '@client/components/client-list';
import { CLIENTS_PER_PAGE } from '@constant/constant';
import { useGlobal } from '@context/GlobalContext';
import { Box } from '@mui/material';
import { ClientListWrapper } from './styled';

const ClientList = () => {
  const { jsxBackend } = useGlobal();
  const BlockList = jsxBackend.get('core.block.listItem');
  return (
    <>
      <ClientTableHeader />
      <Box sx={{ overflow: 'auto', pr: 8, ml: -1, pl: 1 }}>
        <ClientListWrapper>
          <BlockList
            itemPerPage={CLIENTS_PER_PAGE}
            itemName="client.item.clientListItem"
            emptyName="client.block.clientListEmpty"
            module="client"
            resource="client"
            apiUrl="/clients"
            hasPagination={true}
          />
        </ClientListWrapper>
      </Box>
    </>
  );
};

export default ClientList;
