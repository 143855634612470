/*
name: flight.item.flightListItemSkeleton
notLazy: true
*/

import { Skeleton } from '@mui/material';
import { FlightItemWrapper } from './styled';
const FlightTableItemSkeleton = () => {
  return (
    <FlightItemWrapper className='flex-column'>
      <Skeleton
        variant="rectangular"
        height={71}
        width="100%"
        sx={{ backgroundColor: 'neutral.light1' }}
      />
      <Skeleton
        variant="rectangular"
        height={113}
        width="100%"
        sx={{ backgroundColor: 'neutral.light3' }}
      />
    </FlightItemWrapper>
  );
};

export default FlightTableItemSkeleton;
