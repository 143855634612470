import BlockList from '@common/block/BlockList';
import { FLIGHTS_PER_PAGE } from '@constant/constant';
import { FlightEmbedListWrapper } from './styled';
import useScrollRef from '@utils/scroll/useScrollRef';
import { Box } from '@mui/material';
import { flatStringParams } from '@helper/stringFunction';
import { useSelector } from 'react-redux';
import { listEntitySelector } from '@core/selector/entitySelector';
import FlightEmbedTableHeader from './Header';

const FlightEmbedBlock = ({
  user_id,
  is_upcoming,
  apiUrl = '/flights'
}: {
  user_id: string;
  is_upcoming: number;
  apiUrl?: string;
}) => {
  const apiParams = {
    user_id,
    is_upcoming,
    view: 'client_flights',
    limit: 20
  };
  const paging = apiUrl.concat(flatStringParams(apiParams));

  const data = useSelector(state =>
    listEntitySelector(state, paging, 'flight', 'flight')
  );
  const ref = useScrollRef();
  return (
    <Box ref={ref} sx={{ overflow: 'auto', height: '100%', pb: 8 }}>
      {data?.data?.length !== 0 || data?.loading ? (
        <FlightEmbedTableHeader />
      ) : null}
      <FlightEmbedListWrapper>
        <BlockList
          itemPerPage={FLIGHTS_PER_PAGE}
          itemName="flight.item.embedItemClientDetail"
          emptyName="flight.block.embedFlightListEmpty"
          module="flight"
          resource="flight"
          apiUrl={apiUrl}
          hasPagination={false}
          canLoadMore={true}
          apiParams={apiParams}
        />
      </FlightEmbedListWrapper>
    </Box>
  );
};

export default FlightEmbedBlock;
