import { clientsReducer } from '@client/reducer';
import clientSaga from '@client/saga';
import { GlobalContext, useGlobal } from '@context/GlobalContext';
import { coreReducer } from '@core/reducer';
import coreSaga from '@core/saga';
import { crewReducer } from '@crew/reducer';
import crewSaga from '@crew/saga';
import { flightsReducer } from '@flight/reducer';
import flightSaga from '@flight/saga';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import registerSaga from '@register/saga';
import { userReducer } from '@user/reducer';
import userSaga from '@user/saga';
import { ReactNode, useContext, useEffect } from 'react';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

const sagaMiddleware = createSagaMiddleware();
const rootSagas = [
  coreSaga,
  userSaga,
  clientSaga,
  registerSaga,
  flightSaga,
  crewSaga
];
function* rootSaga() {
  try {
    yield all(rootSagas.flat());
  } catch (error) {}
}
const reducer = combineReducers({
  core: coreReducer.reducer,
  user: userReducer.reducer,
  client: clientsReducer.reducer,
  flight: flightsReducer.reducer,
  crew: crewReducer.reducer
});

const rootReducer = (state: any, action: any) => {
  const essentialReducer = { core: state?.core, user: state?.user };

  if (action.type === 'USER_LOGOUT') {
    return reducer(undefined, action);
  }
  if (action.type === 'CLIENTS_INIT_STATE') {
    return reducer({ ...essentialReducer, client: state.client }, action);
  }
  if (action.type === 'FLIGHTS_INIT_STATE') {
    return reducer({ ...essentialReducer, flight: state.flight }, action);
  }
  return reducer(state, action);
};
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
      thunk: false
    }).concat(sagaMiddleware)
});

export const ContextSaga = ({ children }: { children: ReactNode }) => {
  const globalContext = useContext(GlobalContext); // Retrieve the current context value

  // Inject the context into the saga middleware on component mount
  useEffect(() => {
    if (globalContext) {
      sagaMiddleware.setContext({ globalContext });
    }
  }, [globalContext]);

  return <>{children}</>; // Render the children normally
};
sagaMiddleware.run(rootSaga);

export default store;
